// Library Imports
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
// Function and Component Imports
// import { Authenticate } from 'services/auth.js'; // OLD LOGIN SYSTEM
import PasswordForget from './PasswordForget';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginMessage: "Please log in to access the site",
      passwordForgetMessage: 'Enter your email to reset your password',
      redirect: false,
      ...INITIAL_STATE,
      passwordForget: false
    }
    // this.authWithGoogle = this.authWithGoogle.bind(this);
  }

  // GOOGLE AUTH REMOVED FOR NOW
  // authWithGoogle() {
  //   this.props.firebase.auth.signInWithPopup(this.props.firebase.googleProvider)
  //     .then((res, err) => {
  //       if (err) {
  //         this.setState({loginMessage: "Unable to sign in with Google"});
  //       } else {
  //         this.setState({ redirect: true });
  //       }
  //     });
  // }

  // GOOGLE BUTTON
  // <button className="login-with-google" onClick={this.authWithGoogle}>
  //   Log in with Google
  // </button>

  componentDidMount() {
    document.body.classList.toggle('dark', false);
  }

  handleChange = e => {
    this.setState({
      text: e.target.value
    });
  }

  // access = () => {
  //   if (Authenticate(this.state.text)) {
  //     this.setState({ redirect: true });
  //     return;
  //   }
  //   alert("Incorrect password!");
  // }

  handleLogin = event => {
    event.preventDefault();
    let args = {};
    let formData = new FormData(document.getElementById('loginForm'));
    for (var pair of formData.entries()) {
      args[pair[0]] = pair[1];
    }
    this.props.firebase
      .doSignInWithEmailAndPassword(args.email, args.password)
      .then(authUser => {
        // this.setState({ ...INITIAL_STATE });
        // this.props.history.push('/timeline');
        this.setState({ redirect: true });
        return;
      })
      .catch(
        error => {
          if (error && error.message)  {
            this.setState({ loginMessage: error.message });
          }
        }
      );
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/' />;
    }
    return (
      <div id="login-container">
        <div id="login-inner-container">
          <div className="title">
            {
              this.state.passwordForget? this.state.passwordForgetMessage: this.state.loginMessage
            }
          </div>
          {
            this.state.passwordForget?
              null
              :
              <form id="loginForm" className="form" onSubmit={this.handleLogin}>
                <input type="text" id="email" name="email" placeholder="Email" onChange={this.onChange}/>
                <input type="password" id="password" name="password" placeholder="Password" onChange={this.onChange}/>
                <button type="submit" className="nav-button">Login</button>
              </form>
          }
        </div>
        <div className="cant-login">
          <button
            onClick={() => { this.setState({passwordForget: !this.state.passwordForget}); }}
            className={this.state.passwordForget? 'nav-button': 'nav-button smaller-button'}>
            {this.state.passwordForget? "Remember your Password?": "Forgot your Password?"}
          </button>
          {
            this.state.passwordForget
            ? <PasswordForget />
            : null
          }
        </div>
        <div className="title">
          If you don't have an account
        </div>
        <Link to="/register" className="nav-link smaller-a">Register</Link>
      </div>
    );
  }
}

// // OLD LOGIN SYSTEM
// // add to return div
//         <div className="explanation">
//           For temporary access, enter password. This function will be disabled shortly. Users with password access must register.
//         </div>
//         <div className="temp-access">
//           <input type="password" name="password" value={this.state.text} placeholder="Password" onChange={this.handleChange} />
//           <button onClick={this.access} className="nav-button">Access</button>
//         </div>

export default Login;
