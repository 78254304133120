// Library Imports
import React, { Component, Fragment } from 'react';
// Media Imports
import tracker_diagram from 'images/tracker_diagram.jpg';
// Function and Component Imports
import Header from 'components/Header';
import Loading from 'components/Loading';
import { withAuthorization } from 'services/session';
import { FetchAbout } from 'services/fetchData';
import { FetchTeam } from 'services/fetchData';
// Style Imports
import './How.css';
import 'styles/AboutPages.css'

class How extends Component {
  constructor(props) {
    super(props);
    this.state = {
      how_to: [
        'The Tracker displays all policy entries on a timeline and through dropdown listings by topic.  Details about each policy appear in a pop-up window with links to the source and predecessor policy documents.',
        'A comment/feedback button appears on each policy entry at the top left.  Corrections and comments are encouraged. The homepage feedback button allows for general comments or feedback.'
        ],
      categories: {},
    };
  }

  componentDidMount() {
    FetchAbout((categories, about, lucasBio, contact) => {
      // acknowledgements is an Obj with [[people], color]
      this.setState({
        lucasDescription: lucasBio.split('\n'),
      });
    });
    FetchTeam((acknowledgements) => {
      // acknowledgements is an Obj with [[people], color]
      this.setState({
        acknowledgements: acknowledgements,
      });
    });
  }

  /*
    \params:
      content - str - string of content, newlines will be replaced with <br>
    \returns:
      res - [elems] - list of <p> for each string, ceperated by <br>
  */
  buildParagraphs = (content) => {
    let res = [];
    for (let i = 0; i < content.length; i++) {
      res.push(
        <p className='title-description' key={i}>{content[i]}</p>
      );
      if (i < content.length - 1) {
        res.push(<br key={'br-' + i}/>);
      }
    }
    return res;
  }

  render() {
    // div containing title and who introduction 
    let how_to_container = this.state.how_to? (
        <div className='about-title-description-container'>
          <div className='about-title-container'>
            <h2 className='about-title'>How to use the Tracker</h2>
            </div>
          {this.buildParagraphs(this.state.how_to)}
        </div>
      )
      :
      <Loading />;


    // list of team
    // let team_list = this.state.acknowledgements? (
    //     <div id='team-container'>
    //       <div id='team-introduction' className='about-content-header'>
    //         <h2 id='team-title' className='content-title'>Made Possible with help from...</h2>
    //       </div>
    //       <div id='team-list'>
    //         {this.buildTeam()}
    //       </div>
    //     </div>
    //     )
    //   :
    //   <Loading />;
    let how_to_diagram = (
      <div id='tracker-diagram-container'>
        <img src={tracker_diagram} alt='diagram detailing how to use the Tracker' id='tracker-diagram'></img>
      </div>
      );

    return(
      <Fragment>
        <Header page='how'/>
        <div className='about-container'>
          {how_to_container}
          {how_to_diagram}
        </div>
      </Fragment>
    )
  }
}

const condition = authUser => {
  return (authUser && authUser.timelineAccess);
}
// protect Timeline with condition
export default withAuthorization(condition)(How);


// **WHAT IS this Tracker** The Immigration Policy Tracking Project comprehensively compiles in a searchable website all immigration policy changes and administrative actions adopted or announced by the Trump administration.  The ImmTracker is a unique resource that comprehensively tracks *all* immigration policy changes since January 2017 *and* includes the source documents and prior policy whenever possible. 
