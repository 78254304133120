// Library Imports
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Function and Component Imports
import { AuthUserContext } from 'services/session';
import Toolbar from './Toolbar';
import LandingToolbar from './LandingToolbar';
// Style Imports
import './Header.css';

class Header extends Component {
  /* add to main-title for guttentag
              <span className="red smaller">Guttentag </span>
  */
  render() {
    if (this.props.page === 'landing') {
      return (
        <div id="landing-header">
          <div id="title-section">
            <div id="main-title">
              <span className='smaller'>Immigration Policy Tracking Project</span>
            </div>
            <div id="sub-title">
              <p>The only comprehensive catalogue of every administrative immigration policy change since January 2017 with access to source documents and predecessor policies.</p>
            </div>
          </div>
          <Link to="/timeline" id="enter" className='nav-link'>
            Enter Tracking Project
          </Link>
          <AuthUserContext.Consumer>
            {authUser => <LandingToolbar authUser={authUser} page={this.props.page}/>}
          </AuthUserContext.Consumer>
        </div>
      );
    }
    return (
      <div id="header" className="banner-header">
        <Link to="/" id="title-section">
          <div id="main-title">
            <span>Immigration Policy Tracking Project</span>
          </div>
        </Link>
        <AuthUserContext.Consumer>
          {authUser => <Toolbar authUser={authUser} page={this.props.page}/>}
        </AuthUserContext.Consumer>
      </div>
    );
  }
}

export default Header;
