// Library Imports
import React from 'react';
import { Redirect, Link } from "react-router-dom";
// Component and Function Imports
import { SubmitComment } from 'services/sendData';

const INITIAL_STATE = {
  email: '',
  password1: '',
  password2: '',
  error: null,
};

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      registerMessage: 'This is a restricted site. Please register to request access. Your request will be processed within 48 hours.',
      redirect: false,
      destination: null,
      ...INITIAL_STATE
    };
    this.handleRegister = this.handleRegister.bind(this);
  }

   onChange = event => {
     this.setState({ [event.target.name]: event.target.value });
   };

   onCheck = event => {
     this.setState({
       [event.target.name]: document.getElementById(event.target.name).checked
     });
   };

  handleRegister(event) {
    event.preventDefault();
    let args = {};
    let formData = new FormData(document.getElementById('registerForm'));
    for (var pair of formData.entries()) { args[pair[0]] = pair[1]; }
    let {email, password1, password2, firstName, lastName, affiliation} = args;
    if (email.length === 0) {
      this.setState({registerMessage: 'Please fill out your email.'});
      return;
    }
    if (password1 !== password2) {
      this.setState({registerMessage: 'Entered passwords do not match, please try again.'});
      return;
    }
    if (firstName.length === 0) {
      this.setState({registerMessage: 'Please enter your first name.'});
      return;
    }
    if (lastName.length === 0) {
      this.setState({registerMessage: 'Please enter your last name.'});
      return;
    }
    if (password1.length === 0) {
      this.setState({registerMessage: 'Please enter a password.'});
      return;
    }
    if (affiliation.length === 0) {
      this.setState({registerMessage: 'Please enter your organization.'});
      return;
    }
    if (!this.state.terms) {
      this.setState({registerMessage: 'Please read and agree to our terms.'});
      return;
    }
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, password1)
      .then(authUser => {
        // Create a user in Firebase database
        let timelineAccess = false;
        let admin = false;
        let signupDate = JSON.stringify(new Date());
        return this.props.firebase
          .user(authUser.user.uid)
          .set({
            firstName,
            lastName,
            affiliation,
            email,
            timelineAccess,
            admin,
            signupDate,
          });
      })
      .then(() => {
        SubmitComment(
          firstName + " " + lastName + " has signed up for an account",
          "first name: " + firstName + "\nlast name: " + lastName +
          "\naffiliation: " + affiliation + "\nemail: " + email, (response) => {
          return response;
        });
      })
      .then(() => {
        console.log('register complete');
        this.setState({ ...INITIAL_STATE });
        // this.props.history.push('/timeline');
        this.setState({ redirect: true });
        return;
      })
      .catch(
        error => {
          if (error && error.message)  {
            this.setState({ registerMessage: error.message });
          }
        }
      );
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/timeline' />;
    }
    return (
      <div id="register-container">
        <div id="register-inner-container">
          <h3 className="title register">{this.state.registerMessage}</h3>
          <form id="registerForm" className="form" name="registerForm" onSubmit={this.handleRegister}>
            <input type="text" id="firstName" name="firstName" placeholder="First name" onChange={this.onChange}/>
            <input type="text" id="lastName" name="lastName" placeholder="Last name" onChange={this.onChange}/>
            <input type="text" id="affiliation" name="affiliation" placeholder="Affiliation/ Organization" onChange={this.onChange}/>
            <input type="text" id="email" name="email" placeholder="Email" onChange={this.onChange}/>
            <input type="password" id="password1" name="password1" placeholder="Password" onChange={this.onChange}/>
            <input type="password" id="password2" name="password2" placeholder="Confirm password" onChange={this.onChange}/>
            <div id="terms-container">
              <input type="checkbox" name="terms" id="terms" onChange={this.onCheck} />
              I understand that this is in beta version and may be incomplete.
              I agree not to share access to the website without express permission.
            </div>
            <button type="submit" className="nav-button">
              Register
            </button>
          </form>
        </div>
        <div className="title">
          If you have an account
        </div>
        <Link to="/login" className="nav-link smaller-a">Sign In</Link>
      </div>
    );
  }
}

export default Register;
