// Library Imports
import React, { Component } from 'react';
// Function and Component Imports
import { SendEmail } from 'services/sendData';
// Style Imports
import './UserEntry.css';

class UserEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }

  // grant timeline access to user with uid
  grantAccess = (uid, email) => {
    this.props.firebase.user(uid).update({
      timelineAccess: true,
      accessDate: JSON.stringify(new Date()),
    });
    var accessSubject = "Access Granted - Imm Policy Tracker"
    var accessMessage = "Your now have access to the Immigration Policy Tracking Project website. Please go to the website https://immpolicytracking.org to sign in with your email and password."
    SendEmail(email, accessSubject, accessMessage);
  };

  // revoke timeline access to user with uid
  holdAccess = (uid) => {
    this.props.firebase.user(uid).update({
      timelineAccess: false,
      accessDate: null,
      holdAccess: true
    });
  };

  undoHoldAccess = (uid) => {
    this.props.firebase.user(uid).update({
      timelineAccess: false,
      accessDate: null,
      holdAccess: false
    });
  }

  // revoke timeline access to user with uid
  revokeAccess = (uid) => {
    this.props.firebase.user(uid).update({
      timelineAccess: false,
      accessDate: null,
    });
  };

  // build button for a user entry
  buildButton = (user, uid) => {
    if (user.admin) { return; } // no button for admin
    if (user.timelineAccess) {
      return (
        <button
          className="revoke-access"
          onClick={() => this.revokeAccess(uid)}>
          Revoke Access
        </button>
      );
    } else { // user has no access
      let buttons = [];
      if (user.holdAccess) {
        buttons.push(
          <button
            className="unhold-access"
            onClick={() => this.undoHoldAccess(uid)}>
            Allow Processing
          </button>);
      } else {
        buttons.push(
          <button
            className="hold-access"
            onClick={() => this.holdAccess(uid)}>
            Hold Access
          </button>);
        buttons.push(
          <button
            className="grant-access"
            onClick={() => this.grantAccess(uid, user.email)}>
            Grant Access
          </button>);
      }
      return buttons;
    }
  }

  // Takes string that represents a date and returns a reformatted verision
  formatDate = (date) => {
    date = new Date(date);
    let monthNames = [
      "January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December"
    ];
    let day = date.getDate(), monthIndex = date.getMonth(), year = date.getFullYear();
    return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }

  render() {
    if (!this.state.open) {
      return (
        <div className="user-entry-horizontal-view" key={this.props.uid}>
          <p className="name">{this.props.user.firstName + ' ' + this.props.user.lastName}</p>
          <button className="open" onClick={() => this.setState({open: !this.state.open})}>open</button>
        </div>
      );
    }
    return (
      <div className="user-entry" key={this.props.uid}>
        <p className="name">{this.props.user.firstName + ' ' + this.props.user.lastName}</p>
        <p className="affiliation">{this.props.user.affiliation}</p>
        <p className="email">{this.props.user.email}</p>
        <p className="signupDate">
          Signed up: {this.formatDate(JSON.parse(this.props.user.signupDate))}
        </p>
        {this.buildButton(this.props.user, this.props.uid)}
        <button className="open" onClick={() => this.setState({open: !this.state.open})}>close</button>
      </div>
    );
  }
}

export default UserEntry;
