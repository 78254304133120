import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import LoginRegister from '../../containers/LoginRegister';
import AuthUserContext from './context';
import { withFirebase } from '../firebase';

// wrapper for Components to
const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {

    render() {
      return (
        <AuthUserContext.Consumer>
          {
          authUser =>
            condition(authUser) ? <Component {...this.props} />
            :
            <LoginRegister login={false}/>
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return compose(
    withRouter,
    withFirebase
  )(WithAuthorization);
}

export default withAuthorization;
