// Library Imports
import React, { Component } from 'react';
// import CommonMark from 'commonmark'; // for parsng
// import ReactRenderer from 'commonmark-react-renderer';
// Media Imports
import tracker_diagram from 'images/tracker_diagram.jpg';
// Function and Component Imports
// import { FetchHowTo } from 'services/fetchData';
// Style Imports
import './HowTo.css';

/* HowTo
  Holds text explaining how to use Timeline
*/
class HowTo extends Component {
  render() {
    return(
      <div id='how-to-container'>
        <img src={tracker_diagram} alt='diagram detailing how to use the Tracker' id='how-to-diagram'></img>
      </div>
    );
  }
}

export default HowTo;
