// Library Imports
import React, { Component, Fragment } from 'react';
// Meida Imports
import backArrows from 'images/back-arrows.png';
import zoomIn from 'images/zoom-in.png';
import zoomOut from 'images/zoom-out.png';
// Function and Component Imports
import TimelineDrawing from './TimelineDrawing';
// import HoverPopUp from 'components/HoverPopUp';
// import CategoryRect from './CategoryRect';
import Detail from '../Detail';
import ListView from './ListView';

class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 900,
      height: 100,
      zoom: 5,
      zoomLevels: [
        // 1w, 1m, 3m, 6m, 1yr, 3yr, max
        604800000, 2592000000, 7889238000, 15778476000, 31556952000, 94670856000, "max"
      ],
      yTranslate: 0,
      showSelectedItem: false,
      selectedCategory: null,
    }
  }

  componentWillMount() {
    document.body.classList.toggle('dark', false);
    this.setState({
      width: window.innerWidth-300-25-6
    });
    document.addEventListener("keydown", this.onKeyPressed);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyPressed);
  }

  onKeyPressed = (e) => {
    if (this.state.showSelectedItem) {
      if (e.key === "Escape") { this.closeDetail(); }
      if (e.key === "ArrowRight") { this.nextItem(); }
      if (e.key === "ArrowLeft") { this.prevItem(); }
    }
    else {
      if (e.key === "ArrowRight") { this.moveRight(); }
      if (e.key === "ArrowLeft") { this.moveLeft(); }
    }
  };


  prevItem = () => {
    var prevItem = null;
    if (this.state.selectedCategory !== null || (this.state.search && this.state.search.shown)) {
      const i = this.state.selectedCategory;
      const data = this.props.data[i].entries.slice().reverse();
      if (this.state.selectedItem.orderedIndex-1 > -1) {
        prevItem = data[this.state.selectedItem.orderedIndex-1];
        this.selectedItem(prevItem, prevItem.category, true);
      }
    } else {
      if (this.state.selectedItem.orderedIndex-1 > -1) {
        prevItem = this.props.orderedData[this.state.selectedItem.orderedIndex-1];
        this.selectedItem(prevItem, prevItem.category, true);
      }
    }
  };

  nextItem = () => {
    var nextItem = null;
    if (this.state.selectedCategory !== null || (this.state.search && this.state.search.shown)) {
      const i = this.state.selectedCategory;
      const data = this.props.data[i].entries.slice().reverse();
      if(this.state.selectedItem.orderedIndex+1 < data.length) {
        nextItem = data[this.state.selectedItem.orderedIndex+1];
        this.selectedItem(nextItem, nextItem.category, true);
      }
    } else {
      if(this.state.selectedItem.orderedIndex+1 < this.state.orderedData.length) {
        nextItem = this.state.orderedData[this.state.selectedItem.orderedIndex+1];
        this.selectedItem(nextItem, nextItem.category, true);
      }
    }
  };

  selectedItem = (d, category, scrollTo) => {
    var orderedIndex = -1;
    if (this.state.selectedCategory !== null) {
      const i = this.state.selectedCategory;
      const data = this.props.data[i].entries;
      data.slice().reverse().forEach((data, index) => {
        if(d.title === data.title && d.date === data.date && d.description === data.description) {
          orderedIndex = index;
        }
      });
    } else {
      this.props.orderedData.forEach((data, index) => {
        if (data.category.label === category.label && d.title === data.title &&
           d.date === data.date && d.description === data.description) {
          orderedIndex = index;
        }
      });
    }
    this.setState({
      selectedItem: {data: d, category: category, orderedIndex: orderedIndex, scrollTo: scrollTo},
      yTranslate: scrollTo ? 0 : this.state.yTranslate,
      showSelectedItem: true
    });
    // this.showDetail();
  };

  zoomIn = () => {
    if (this.state.zoom > 0) { // check if can zoom in
      this.setState({zoom: this.state.zoom-1, yTranslate: 0});
    }
  };

  zoomOut = () => {
    // check zoom range
    // const currentScale = zoom.levels[this.state.zoom.current];
    // const minTime = this.state.orderedData[0].date,
    //       maxTime = this.state.orderedData[this.state.orderedData.length-1].date;
    // check if can zoom out
    // if (currentScale !== "max" && currentScale > maxTime-minTime) { return; }
    if (this.state.zoom < this.state.zoomLevels.length-1) {
      this.setState({zoom: this.state.zoom+1, yTranslate: 0});
    }
  };

  categorySelected = (index) => {
    var selected = index;
    if (index === this.state.selectedCategory) {
      selected = null;
    }
    this.setState({selectedCategory: selected});
  };

  moveRight = () => {
    this.setState({yTranslate: this.state.yTranslate+1});
  };

  moveLeft = () => {
    this.setState({yTranslate: this.state.yTranslate-1});
  };

  closeDetail = () => {
    this.setState({showSelectedItem: false});
  };

  showDetail = () => {
    this.setState({showSelectedItem: true});
  };

  categoryMouseEnter = (e) => {
    var message = "Click to show all events for this category."
    if (parseInt(e.target.getAttribute('name')) === this.state.selectedCategory) {
      message = "Click to go back to the full timeline view.";
    }
    const bounding = e.target.getBoundingClientRect();
    this.setState({
      hovered: {
        message: message,
        x: bounding.x + (bounding.width) - 40,
        y: bounding.top + window.scrollY + 10
      }
    });
  };

  categoryMouseExit = (e) => {
    this.setState({
      hovered: null
    });
  };

  render() {
    var hover = null;
    if (this.state.hovered && this.state.hovered.message) {
      hover = (
        <div className="hover" style={{
          left: this.state.hovered.x + "px",
          top: this.state.hovered.y + "px",
          }}>
          {this.state.hovered.message}
        </div>
      );
    }
    var detailedItem = null;
    if (this.state.showSelectedItem) {
      detailedItem = (
        <Detail
          item={this.state.selectedItem}
          closeDetail={this.closeDetail}
          />
      );
    }
    let data = this.props.data;
    let height = this.props.data.length*38 + 30*2.75;
    var categories = data.map((d, i) => {
      const label = d.label.replace(/\//g, " • ").toUpperCase();
      var style = {background: "#" + d.color};
      if (this.state.selectedCategory !== null) {
        if (this.state.selectedCategory !== i) {
          style.opacity = 0.5;
        }
      }
      return (
        <div className="category" style={style} key={i} name={i}
            onClick={() => this.categorySelected(i)} onMouseEnter={this.categoryMouseEnter}
            onMouseLeave={this.categoryMouseExit}>
          <h3 name={i}>{label}</h3>
          <div className="entries-count"><p>{d.entries.length}</p></div>
        </div>
      );
    });
    var item = null;
    if (this.state.selectedCategory !== null) {
      const i = this.state.selectedCategory;
      const d = data[i];
      data = [d];
      height = 36*2.75;
      const marginTop = 36*(this.props.data.length - 1) + 30;
      item = (
        <ListView
          data={d}
          search={false}
          showCat={this.props.data.length - 1 === i}
          selectedItem={this.selectedItem}
          showDetail={this.showDetail}
          detailedItem={detailedItem}
          marginTop={marginTop}
          timelineHeight={height}
          />
      );
    }
    return (
      <Fragment>
        <div id="timeline-container" style={detailedItem ? {opacity: 0.25} : {}}>
          <div id="left-sidebar">
            <div id="action-buttons">
              <button className="image-btn" onClick={this.zoomIn}>
                <img src={zoomIn} alt="zoomIn" />
              </button>
              <button className="image-btn" onClick={this.zoomOut}>
                <img src={zoomOut} alt="zoomOut" />
              </button>
              <button className="image-btn" onClick={this.moveLeft}>
                <img src={backArrows} alt="scrollLeft" />
              </button>
              <button className="image-btn" onClick={this.moveRight}>
                <img src={backArrows} className="reversed" alt="scrollRight" />
              </button>
            </div>
            <div id="categories">{categories}</div>
          </div>
          <TimelineDrawing
            zoom={this.state.zoom}
            yTranslate={this.state.yTranslate}
            data={data}
            selectedItem={this.state.selectedItem}
            width={this.state.width}
            height={height}
            itemClicked={this.selectedItem}
            id={"timeline"} />
        </div>
        {item}
        {detailedItem}
        {hover}
      </Fragment>
    );
  }
}

export default Timeline;
