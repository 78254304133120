// Library Imports
import React, {Fragment} from 'react';
// Function and Component Imports
import Header from 'components/Header';
import Login from './Login';
import Register from './Register';
import { FirebaseContext } from 'services/firebase';
// Style Imports
import './Login.css';

class LoginRegister extends React.Component {
  constructor(props) {
    super(props);
  }

  // renders two forms, one for log in and one for register
  render() {
    return (
      <Fragment>
      <Header
        page="login-register"
      />
      {
        this.props.login?
        <FirebaseContext.Consumer>
          {firebase => <Login loginStateUpdate={this.props.loginStateUpdate} firebase={firebase}/>}
        </FirebaseContext.Consumer>
        :
        <FirebaseContext.Consumer>
          {firebase => <Register loginStateUpdate={this.props.loginStateUpdate} firebase={firebase}/>}
        </FirebaseContext.Consumer>
      }
      </Fragment>
    );
  }
}

export default LoginRegister;
