// Library Imports
import React, {Component} from 'react';
// Media Imports
import close from 'images/close.png';
import filter from 'images/filter.png';
// Style Imports
import './FilterDropdown.css';
import { PRIORITY_FITLER } from 'constants.js'

class FilterDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: this.props.filter.category,
      agency: this.props.filter.agency,
      start: this.props.filter.start,
      end: this.props.filter.end,
      showPriority1: this.props.filter.showPriority1,
      showPriority2: this.props.filter.showPriority2,
      showPriority3: this.props.filter.showPriority3,
      showPriorityUndef: this.props.filter.showPriorityUndef,
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  clearFilter = () => {
    this.props.setFilter({
      show: false,
      category: "- SELECT CATEGORY -",
      agency: "- SELECT AGENCY -",
      start:"",
      end: "",
      showPriority1: true,
      showPriority2: true,
      showPriority3: true,
      showPriorityUndef: true,
    });
  }

  handleDateChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  handleCheckChange = (e) => {
    this.setState({
       [e.target.name]: document.getElementById(e.target.name).checked
     });
  }

  setFilter = () => {
    this.props.setFilter({
      show: false,
      category: this.state.category, 
      agency: this.state.agency, 
      start: this.state.start,
      end: this.state.end,
      showPriority1: this.state.showPriority1,
      showPriority2: this.state.showPriority2,
      showPriority3: this.state.showPriority3,
      showPriorityUndef: this.state.showPriorityUndef
    });
  };

  buildCheckboxs = () => {
    if (!PRIORITY_FITLER) return null;
    let res = [], priorities = ['1', '2', '3', 'Undef'], names = ['1', '2', '3', 'Undefined'];
    for (let i = 0; i < 4; i++) {
      res.push(
        <div className="checkbox-input-wrapper" key={i}>
          <div className="input-header">{"Show Priority " + names[i] + " Entries "}</div>
          <input type="checkbox" name={"showPriority" + priorities[i]} id={"showPriority" + priorities[i]}
            checked={this.state['showPriority' + priorities[i]]} onChange={this.handleCheckChange}
            />
        </div>
      );
    }
    return <div className="input-container">{res}</div>
  };

  render() {
    var categories = [];
    categories.push((
      <option value="- SELECT CATEGORY -" key={-1}> - SELECT CATEGORY - </option>
    ));
    for (var i = 1; i < this.props.allData.length; i++) {
      categories.push((
        <option value={this.props.allData[i].label} key={i}> {this.props.allData[i].label} </option>
      ));
    }
    var agencies = [];
    for (i = 0; i < this.props.agencies.length; i++) {
      agencies.push((
        <option value={this.props.agencies[i]} key={i}> {this.props.agencies[i]} </option>
      ));
    }

    return (
      <div className="filter-dropdown">
        <div className="title">
          <img className="icon" src={filter} alt="filter" />
          <span> Filter Data </span>
          <button onClick={() => this.props.toggleFilter(false)}>
            <img src={close} alt="close" />
          </button>
        </div>

        <div className="input-container">
          <div className="input-header"> DATE RANGE </div>
          <div className="dates-wrapper">
            <input type="date" name="start" value={this.state.start} onChange={this.handleDateChange}></input>
            <span> to </span>
            <input type="date" name="end" value={this.state.end} onChange={this.handleDateChange}></input>
          </div>
        </div>

        <div className="input-container">
          <div className="input-header"> CATEGORY </div>
          <select name="category" value={this.state.category} onChange={this.handleChange}>
            {categories}
          </select>
        </div>

        <div className="input-container">
          <div className="input-header"> AGENCY AFFECTED </div>
          <select name="agency" value={this.state.agency} onChange={this.handleChange}>
            {agencies}
          </select>
        </div>

        {this.buildCheckboxs()}

        <div className="buttons">
          <button
            className="nav-link-loose"
            onClick={() => this.setFilter()}>
            Apply
          </button>
          <button
            className="nav-link-loose"
            onClick={this.clearFilter}>
            Reset
          </button>
        </div>
      </div>
    );
  }
}

export default FilterDropdown;
