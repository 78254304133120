/* fetchLanding.js
  exports function to retreive data for Landing Container
*/
import { parseCategories, FetchSheets } from './helpers';
import {VARIOUS_PAGE_DATA_INDEX, LANDING_ROW } from './constants';

// fetch data for the landing page
function FetchLanding(callback) {
  // fetch sheet 0
  // sheetsRes - response object
  FetchSheets([VARIOUS_PAGE_DATA_INDEX], (sheetsRes) => {
    callback(
      parseCategories(sheetsRes), // list of catgeories [label, color, description]
      sheetsRes.data.valueRanges[VARIOUS_PAGE_DATA_INDEX].values[LANDING_ROW][3], // landing page description text
      sheetsRes.data.valueRanges[VARIOUS_PAGE_DATA_INDEX].values[LANDING_ROW][4] // contact text
    );
  });
}

export default FetchLanding;
