import React, { Component } from 'react';
import * as d3 from 'd3';
import CommonMark from 'commonmark';
import ReactRenderer from 'commonmark-react-renderer';

import { HexToRgb } from 'services/helpers.js';

import './ListView.css';

class ListView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: []
    };

    this.orderData = this.orderData.bind(this);
    this.itemSelected = this.itemSelected.bind(this);
  }

  itemSelected(i) {
    if (i < this.state.data.length && i > -1) {
      var item = this.state.data[i];
      this.props.selectedItem(item, item.category, false);
      this.props.showDetail();
    }
  }

  orderData() {
    const data = this.props.data.entries.sort((a, b) => {
      if (a.date === b.date) {
        var x = a.title.toLowerCase();
        var y = b.title.toLowerCase();
        if (x === y) {
          x = a.description.toLowerCase();
          y = b.description.toLowerCase();
          if (x < y) {return -1;}
          if (x > y) {return 1;}
        }
        if (x < y) {return -1;}
        if (x > y) {return 1;}
      }
      return a.date - b.date;
    }).reverse();
    this.setState({data: data});
  }

  componentDidMount() {
    this.orderData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      this.orderData();
    }
  }

  render() {
    const parser = new CommonMark.Parser();
    const renderer = new ReactRenderer({linkTarget: "_blank"});

    var items = this.state.data.map((d, i) => {
      const date = new Date(d.date);
      const dateStr = d3.timeFormat("%b %d, %Y")(date).toUpperCase();
      const rgb = HexToRgb("#"+d.color);
      const bg = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5)`;

      var description = d.description;
      if (description.length > 450) {
        description = description.substring(0, 450);
        description += "...";
      }

      var ast = parser.parse(description);
      description = renderer.render(ast);

      if (description.length > 1) {
        description = description[0];
      }

      var category = null;
      if (this.props.showCat) {
        category = (
          <span className="tag">
            [{d.category.label}]
          </span>
        )
      }

      return (
        <div className="list-item" key={i}>
          <div className="date" style={{background: "#" + d.color}}>
            <div className="rotated">
              {dateStr}
            </div>
          </div>
          <div className="title" style={{background: bg}} onClick={() => this.itemSelected(i)}>
            {category}
            {d.title}
          </div>
          <div className="description">
            {description}
            <span className="read-more" onClick={() => this.itemSelected(i)}> Read More </span>
          </div>
        </div>
      );
    });

    var style = {};
    if (this.props.detailedItem) {
      style = {
        "opacity": 0.25,
      };
    }
    style["marginTop"] = -1*this.props.marginTop + "px";
    style["height"] = window.innerHeight-this.props.timelineHeight-84 + "px";
    if (this.props.search) {
      style["height"] = window.innerHeight-84 + "px";
    }

    return (
      <div className={"list-container-wrapper " + (this.props.search ? "search-list" : "timeline-list")} style={style}>
        <div className="list-container">
        {items}
      </div>
      </div>
    );
  }
}

export default ListView;
