/*
  functions used to fetch data for Timeline Container
*/
import { parseCategories, FetchSheets } from './helpers';
import {
  VARIOUS_PAGE_DATA_INDEX,
  CATEGORIES_ROW, NUM_CATEGORIES,
  TIMELINE_ROW
} from './constants';
import {
  DESCRIPTORS_INDEX,
  ANNOUNCED_DATE_I,
  EFFECTIVE_DATE_I,
  TITLE_I,
  POLICY_I,
  POLICY_DOC_I,
  TYPE_ACTION_I,
  AGENCIES_AFFECTED_I,
  PRIOR_POLICY_I,
  PRIOR_POLICY_DOC_I,
  SUBSEQUENT_ACTION_I,
  SUBSEQUENT_ACTION_DOC_I,
  SEARCH_TERMS_I,
  REPORTED_I,
  PRIORITY_I,
  NEW_INDICIES,
  OLD_INDICIES
} from './constants';

// findIntInStr returns the number container within the string
function findIntInStr(str) {
  // let regex = /.*(\d+)/
  let match = /.*(\d+)/.exec(str);
  if (match && match[1]) return match[1];
  return undefined;
}

// Defines an entry in the Timeline
// Each entry is defined as:
// Entry = {
//   date: date associated with entry,
//   color: color for entry,
//   title: title to entry,
//   description: description about entry,
//   [new]: new descriptors for entry,
//   [old]: old descriptors for entry
// }
//
class Entry {
  constructor(rawRow, descriptors, color) {
    let dateString = rawRow[ANNOUNCED_DATE_I];
    let dateParts = dateString.split('/');
    // hack for getting consistency between dates
    let monthMap = {
      1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 
      6: 'June', 7: 'July', 8: 'August', 9: 'September', 10: 'October',
      11: 'November', 12: 'December'
    };
    let month = Number.isInteger(parseInt(dateParts[0]))? monthMap[dateParts[0]]: dateParts[0];
    let year = (dateParts[2].length == 4)? dateParts[2]: `20${dateParts[2]}`;
    // console.log(dateParts);
    this.date = new Date(`${month} ${dateParts[1]}, ${year}`).getTime();
    console.log(`${month} ${dateParts[1]}, ${year}`, this.date);
    this.color = color;
    this.title = rawRow[TITLE_I];
    this.description = rawRow[POLICY_I];
    this.new = {};
    this.old = {};
    this.reported = false;
    // iterate through types
    this.searchWords = (typeof(rawRow[SEARCH_TERMS_I]) !== 'undefined')? rawRow[SEARCH_TERMS_I].split(/[\s,\n]+/): [];
    this.agencyAffected = (typeof(rawRow[AGENCIES_AFFECTED_I]) !== 'undefined')? rawRow[AGENCIES_AFFECTED_I].replace(/;/g, ",").replace(/\//g, ","): '';
    this.agencyAffected = this.agencyAffected.split(",");
    for (let j = 0; j < this.agencyAffected.length; j++) {
      this.agencyAffected[j] = this.agencyAffected[j].trim();
    }
    let reported = rawRow[REPORTED_I];
    if (reported && reported.toLowerCase().trim() === "r") 
      this.reported = true;
    this.priority = findIntInStr(rawRow[PRIORITY_I]);
    this.searchTerms = rawRow[SEARCH_TERMS_I];
    for (let [descriptor, index] of Object.entries(NEW_INDICIES)) {
      this.new[descriptor] = rawRow[index];
    }
    for (let [descriptor, index] of Object.entries(OLD_INDICIES)) {
      this.new[descriptor] = rawRow[index];
    }
    /*
    for (let i = 11; i < descriptors.length; i++) {
      let val = rawRow[i];
      let type = newOrOld[i], descriptor = descriptors[i];
      if (type === "search" && val) { this.searchWords = val.split(/[\s,\n]+/); }
      else if (descriptor && descriptor.toLowerCase().trim() === "agency affected") {
        val = val.replace(/;/g, ",").replace(/\//g, ",");
        this.agencyAffected = val.split(",");
        for (let j = 0; j < this.agencyAffected.length; j++) {
          this.agencyAffected[j] = this.agencyAffected[j].trim();
        }
      } else if (descriptor &&
                 descriptor.toLowerCase().trim() === "reported or actual change in policy") {
        if (val && val.toLowerCase().trim() === "r") { this.reported = true; }
      } else if (descriptor &&
                 descriptor.toLowerCase().trim() === "priority/importance (1 = highest, 3 = lowest)") {
        this.priority = findIntInStr(val);
      } else if (descriptor && descriptor.toLowerCase().trim() === "add search terms") {
        this.searchTerms = val;
      } else {
        if (type) { type = type.toLowerCase().trim(); }
        if (type === "new" || type === "old") { this[type][descriptor] = val; }
      }
    }
    */
  }
}

// Defines a category in the timeline
// Each category is defined as:
// Category = {
//   label: name / label of category
//   color: color for category
//   description: description of category
//   entries: [Entry]
// }
//
class Category {
  constructor(label, color, description, rawCategoryData) {
    this.label = label; this.color = color; this.description = description;
    this.entries = [];
    const descriptors = rawCategoryData.values[DESCRIPTORS_INDEX];
    for (var row = DESCRIPTORS_INDEX + 1; row < rawCategoryData.values.length; row++) {
      const rawRow = rawCategoryData.values[row];
      // check if not an entry TODO check if a valid entry
      if (rawRow.length < POLICY_I|| rawRow[POLICY_I] === undefined || rawRow[ANNOUNCED_DATE_I] === undefined) {
        continue;
      }
      // check if valid date
      if (!rawRow[ANNOUNCED_DATE_I].toLowerCase().includes("/")) {
        continue;
      }
      this.entries.push(new Entry(rawRow, descriptors, color));
    }
  }
}

function parseFilledInCategories(sheetsRes) {
  let categoriesInfo = parseCategories(sheetsRes), categories = [];
  for (let i = 0; i < NUM_CATEGORIES; i++) {
    categories.push(
      new Category(
        categoriesInfo[i].label,
        categoriesInfo[i].color,
        categoriesInfo[i].description,
        sheetsRes.data.valueRanges[i+1] // +1 because of initial global sheet
      )
    );
  }
  return categories;
}

// take unordered list of entries and return them order
// Order is based on date, then if equal, title, then description
function orderData(data) {
  var all = [];
  data.forEach((category) => {
    var items = [];
    category.entries.forEach((time) => {
      time.category = category;
      items.push(time);
    })
    all = [...all, ...items];
  });
  return all.sort(function(a, b) {
    if (a.date === b.date) {
      let x = a.title.toLowerCase();
      let y = b.title.toLowerCase();
      if (x === y) {
        return (a.description.toLowerCase() < b.description.toLowerCase())? -1: 1;
      }
      return (x < y)? -1: 1;
    }
    return a.date - b.date;
  });
}

// returns list of mentioned agencies in allData
function parseAgencies(orderedData) {
  let agencies = {};
  orderedData.forEach((d) => {
    if (d.agencyAffected && d.agencyAffected.length > 0) {
      d.agencyAffected.forEach((agency) => {
        agencies[agency] = 1;
      });
    }
  });
  return Object.keys(agencies).sort();
}

// fetch data for timeline
// This function parses the sheets given by sheetsToGet and calls
// callback on a categories object, which is a array of arrays, which correspond
// to one category in the timeline. Each Category = [title, color, description, [entries]].
//
// This function also builds the all categories category
function FetchTimeline(callback) {
  // sheetsRes - response object
  let sheetsToGet = [0]; // fetch global sheet 0
  // fetch all category pages
  for (let i=1; i<NUM_CATEGORIES+1; i++) { sheetsToGet.push(i); }
  FetchSheets(sheetsToGet, (sheetsRes) => {
    let categories = parseFilledInCategories(sheetsRes);
    // order data and collect agencies
    let orderedData = orderData(categories), agencies = parseAgencies(orderedData);
    // add all categories
    categories.unshift({
      label: "All categories",
      color: sheetsRes.data.valueRanges[VARIOUS_PAGE_DATA_INDEX].values[CATEGORIES_ROW][1], // All Categories Color
      entries: orderedData,
      description: "All the categories."
    });
    console.log(categories);
    callback(
      categories, // Obj containing entries for each category
      agencies,   // List of Mentioned Agencies
      orderedData, // Entries Ordered by Date
      sheetsRes.data.valueRanges[VARIOUS_PAGE_DATA_INDEX].values[TIMELINE_ROW][3], // Search Info Pop Up
      sheetsRes.data.valueRanges[VARIOUS_PAGE_DATA_INDEX].values[TIMELINE_ROW][4], // Add Reported Pop Up
      sheetsRes.data.valueRanges[VARIOUS_PAGE_DATA_INDEX].values[TIMELINE_ROW][5], // Upper Reported Checkbox Label
      sheetsRes.data.valueRanges[VARIOUS_PAGE_DATA_INDEX].values[TIMELINE_ROW][6], // Lower Reported Checkbox Label
      sheetsRes.data.valueRanges[VARIOUS_PAGE_DATA_INDEX].values[TIMELINE_ROW][7], // COVID-19 Checkbox label
    );
  });
}

export default FetchTimeline;
