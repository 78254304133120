// Library Imports
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from 'services/firebase';
//Function and Component Imports
// Style Imports
import 'styles/buttons.css';

const SignOutButton = withFirebase(({firebase}) => (
  <button type="button" className="nav-button-inverted" onClick={firebase.doSignOut}>
    Sign Out
  </button>
));

export default class LandingToolbar extends Component {
  render() {
    return (
      <div id="toolbar">
        {this.props.authUser?
          <SignOutButton/>
          :
          <Link to="/login" className="nav-link-inverted">Sign In</Link>
        }
        {(this.props.authUser && this.props.authUser.admin)?
          <Link to="/admin" className="nav-link-inverted">Admin</Link>
          :
          null
        }
        {(this.props.authUser && this.props.authUser.dev)?
          <Link to="/dev" className="nav-link-inverted">Dev</Link>
          :
          null
        }
      </div>
    );
  }
}