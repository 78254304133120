/*
  functions used to fetch data for Footer Component
*/
import { FetchSheets } from './helpers';
import { VARIOUS_PAGE_DATA_INDEX, FOOTER_ROW } from './constants';
// fetches footer text
function FetchFooter(callback) {
  // fetch sheet 0
  // sheetsRes - response object
  FetchSheets([VARIOUS_PAGE_DATA_INDEX], (sheetsRes) => {
    callback(
      sheetsRes.data.valueRanges[VARIOUS_PAGE_DATA_INDEX].values[FOOTER_ROW][3], // footer text
      sheetsRes.data.valueRanges[VARIOUS_PAGE_DATA_INDEX].values[FOOTER_ROW][2], // footer text-size
      sheetsRes.data.valueRanges[VARIOUS_PAGE_DATA_INDEX].values[FOOTER_ROW][1], // footer text-style
    );
  });
}

export default FetchFooter;
