// Library Imports
import React, { Component } from 'react';

// Style Imports
import './InfoPopUp.css';

class InfoPopUp extends Component {
  render() {
    return (
      <div className="info-pop-up-container">
        <div className="info-pop-up-circle">
          <span className="info-i">i</span>
          <div className="info-pop-up">
            <p className="info-pop-up-text">
              {this.props.text}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default InfoPopUp;