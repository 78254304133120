// Library Imports
import React, {Component, Fragment} from 'react';
import {Route, BrowserRouter, Switch} from 'react-router-dom';
// Function and Component Imports
import TimelineContainer from 'containers/Timeline';
import Landing from 'containers/Landing';
import LoginRegister from 'containers/LoginRegister';
import About from 'containers/About';
import What from 'containers/What';
import Who from 'containers/Who';
import How from 'containers/How';
import Team from 'containers/Team';
import Contact from 'containers/Contact';
import Admin from 'containers/Admin';
import Dev from 'containers/Dev';
import LucasBio from 'containers/LucasBio';
import { withAuthentication } from 'services/session';
// Style Imports
import 'styles/App.css';

class App extends Component {
  render() {
    return (
      <Fragment>
        <BrowserRouter>
          <Switch>
            <Route exact path='/' render={(props) => <Landing {...props} />}/>
            <Route exact path='/login' render={(props) => <LoginRegister {...props} login={true} />}/>
            <Route exact path='/register' render={(props) => <LoginRegister {...props} login={false} />}/>
            <Route exact path='/about' render={(props) => <About {...props} />}/>
            <Route exact path='/what' render={(props) => <What {...props} />}/>
            <Route exact path='/who' render={(props) => <Who {...props} />}/>
            <Route exact path='/how' render={(props) => <How {...props} />}/>
            <Route exact path='/test' render={(props) => <Landing {...props} />}/>
            <Route exact path='/team' render={(props) => <Team {...props} />}/>
            <Route exact path='/admin' render={(props) => <Admin {...props}/>} />
            <Route exact path='/dev' render={(props) => <Dev {...props}/>} />
            <Route exact path='/comment' render={(props) => <Contact {...props} type="comment"/>} />
            <Route exact path='/timeline' render={(props) => <TimelineContainer {...props}/>} />
            <Route exact path='/guttentag' render={(props) => <LucasBio />} />
          </Switch>
        </BrowserRouter>
      </Fragment>
    );
  }
}

/* old routes that don't work anymore
            <Route exact path='/access' render={(props) => <Contact {...props} type="access"/>} />
            <Route exact path='/contact' render={(props) => <Contact {...props} type="contact"/>} />
*/

export default withAuthentication(App);
