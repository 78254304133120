// Library Imports
import React, { Component } from 'react';
// Media Imports
import loading from 'images/loading.gif';
// Style Imports
import "./Loading.css";

class Loading extends Component {
  render() {
    return (
      <div className="loading-data">
        Loading Data...
        <div className="loading-img">
          <img src={loading} alt="loading" />
        </div>
      </div>
    );
  }
}

export default Loading;
