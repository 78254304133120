// Library Imports
import React, { Component, Fragment } from 'react';
// Function and Component Imports
import Loading from 'components/Loading';
import Header from 'components/Header';
import UserEntry from './UserEntry';
import LoadDatabase from './LoadDatabase'
import { withAuthorization } from 'services/session';
import { FirebaseContext } from 'services/firebase';
// Style Imports
import './Admin.css';

// List of users saved in the firebase databse
class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /*
        users is a list of users loaded from firebase
        a user is defined as: user = [ 0: uid, 1: userObj ]
      */
      users: null,
      sortOrders: [
        "A-Z First Name",
        "Z-A First Name",
        "A-Z Last Name",
        "Z-A Last Name",
        "Most Recent Signup Day",
        "Most Former Signup Day"
      ],
      currSortOrder: 0
    };
  }

  // on mount, load user list
  componentDidMount() {
    this.props.firebase.users().on('value', (snapshot) => {
      this.setState({ users: Object.entries(snapshot.val()) });
    });
  }

  // on unmount, turn off reference
  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  getSortChooseButtons = () => {
    let options = [];
    for (let i = 0; i < this.state.sortOrders.length; i++) {
      options.push(
        <option value={i} key={i}>
          {this.state.sortOrders[i]}
        </option>
      );
    }
    return (
      <select
        className="choose-sort"
        value={this.state.value}
        onChange={(e) => this.setState({currSortOrder: e.target.value})}>
        {options}
      </select>
    );
  }

  // Returns this.state.users sorted by sort function corresponding to
  // this.state.currSortOrder
  sortUsers = () => {
    let sortFunctions = [
      /* A-Z first name*/
      (u0, u1) => (u0[1].firstName.toLowerCase() < u1[1].firstName.toLowerCase())? -1: 1,
      /* Z-A first name*/
      (u0, u1) => (u0[1].firstName.toLowerCase() > u1[1].firstName.toLowerCase())? -1: 1,
      /* A-Z last name*/
      (u0, u1) => (u0[1].lastName.toLowerCase() < u1[1].lastName.toLowerCase())? -1: 1,
      /* Z-A last name*/
      (u0, u1) => (u0[1].lastName.toLowerCase() > u1[1].lastName.toLowerCase())? -1: 1,
      /* Most Recent signUpDate */
      (u0, u1) => (new Date(JSON.parse(u0[1].signupDate)) >
                   new Date(JSON.parse(u1[1].signupDate)))? -1: 1,
      /* Most Recent signUpDate */
      (u0, u1) => (new Date(JSON.parse(u0[1].signupDate)) <
                   new Date(JSON.parse(u1[1].signupDate)))? -1: 1,
    ];
    this.state.users.sort(sortFunctions[this.state.currSortOrder]);
  }

  // Build and return list of users seperated by access level
  buildUserLists = () => {
    let admin = [], accessGranted = [], pendingAccess = [], holdingAccess = [];
    if (this.state.users) {
      for (let i = 0; i < this.state.users.length; i++) {
        let user = this.state.users[i][1], uid = this.state.users[i][0];
        let entry = (
          <UserEntry
            user={user} 
            uid={uid}
            key={uid}
            firebase={this.props.firebase} />
          );
        if      (user.admin)          { admin.push(entry);         }
        else if (user.timelineAccess) { accessGranted.push(entry); }
        else if (user.holdAccess)     { holdingAccess.push(entry); }
        else  /* user has no access*/ { pendingAccess.push(entry); }
      }
    }
    return [admin, accessGranted, pendingAccess, holdingAccess];
  }

  // Return a list [str] of users with email access
  buildEmailList = () => {
    let email_list = [];
    if (this.state.users) {
      for (let i = 0; i < this.state.users.length; i++) {
        let user = this.state.users[i][1];
        if (user.admin || user.timelineAccess) {
          email_list.push(' ' + user.email);
        }
      }
    }
    return email_list; 
  }

  render() {
    if (!this.state.users) { return <Loading />; }
    this.sortUsers();
    let [admin, accessGranted, pendingAccess, holdingAccess] = this.buildUserLists();
    return (
      <Fragment>
        <div className="choose-sort-container">
          <h2>Sort Options</h2>
          {this.getSortChooseButtons()}
        </div>
        <div id="user-list-container">
          <div className="user-list" id="pending-list">
            <h2 className="list-title">Pending Access</h2>
            {pendingAccess}
            <h2 className="list-title">On Hold</h2>
            {holdingAccess}
          </div>
          <div className="user-list" id="access-list">
            <h2 className="list-title">Access Granted</h2>
            {accessGranted}
          </div>
          <div className="user-list" id="admin-list">
            <h2 className="list-title">Admin</h2>
            {admin}
          </div>
        </div>
        <div id='email-container'>
          <span>{this.buildEmailList()}</span>
        </div>
      </Fragment>
    );
  }
}

/* load db
        <FirebaseContext.Consumer>
          {firebase => <LoadDatabase firebase={firebase}/>}
        </FirebaseContext.Consumer>
*/

class Admin extends Component {
  render() {
    return (
      <Fragment>
        <Header page="admin"/>

        <FirebaseContext.Consumer>
          {firebase => <UserList firebase={firebase}/>}
        </FirebaseContext.Consumer>
      </Fragment>
    );
  }
}

// only admin can access
const condition = authUser => {
  return (authUser && authUser.admin);
}
export default withAuthorization(condition)(Admin);
