// Library Imports
import React, { Component } from 'react';
// Component and Function Imports
import { FetchLanding } from 'services/fetchData';

class LoadDatabase extends Component {
  constructor(props) {
    super(props);
  }

  loadDatabse = () => {
    FetchLanding((categories, landingDescription, contact) => {
      this.props.firebase.db.ref('pages/' + 'landing').set({
        categories: categories,
        landingDescription: landingDescription,
        contact: contact
      });
    });
  }

  render() {
    return (
      <div id='load-database-container'>
        <button
          id='load-database-button'
          onClick={() => this.loadDatabase()}>
          Load Database
          </button>
      </div>
    );
  }
}

export default LoadDatabase;