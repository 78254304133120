// Library Imports
import React, { Component, Fragment } from 'react';
// Media Imports
import loading from 'images/loading.gif';
// Function and Component Imports
import { FetchTeam } from 'services/fetchData';
// import { IsAuthenticated } from 'services/auth'; // OLD LOGIN SYSTEM
import { withAuthorization } from 'services/session';
import Header from 'components/Header';
import Overlay from 'components/Overlay';
// Style Imports
import './Team.css';

class Team extends Component {
  constructor(props) {
    super(props);

    this.state = {
      acknowledgements: [],
      showContact: false,
    }

    this.closeDetail = this.closeDetail.bind(this);
    this.showDetail = this.showDetail.bind(this);
  }

  componentDidMount() {
    FetchTeam((acknowledgements) => {
      // acknowledgements is an Obj with [[people], color]
      this.setState({
        acknowledgements: acknowledgements,
      });
    });
    document.body.classList.toggle('dark', false);
  }

  closeDetail() {
    this.setState({showContact: false});
  }

  showDetail() {
    this.setState({showContact: true});
  }

  render() {
    var acknowledgements = null;
    var loadingSection = null;
    if (Object.keys(this.state.acknowledgements).length === 0) {
      loadingSection = (
        <div className="loading-data">
          Loading acknowledgements...
          <div className="loading-img">
            <img src={loading} alt="loading" />
          </div>
        </div>
      );
    } else {
      acknowledgements = [];
    }

    for (let i = 0; i < this.state.acknowledgements.length; i++) {
      let thanksCat = this.state.acknowledgements[i];
      var people = [];
      for (let j = 0; j < thanksCat.people.length; j++) {
        people.push((
          <div className="name" key={j}>
            {thanksCat.people[j]}
          </div>
        ));
      }
      const style = {border: `3px solid #${thanksCat.color}`};
      acknowledgements.push((
        <div className="acknowledgement" key={thanksCat.type}>
          <div className="title">
            {thanksCat.type}
          </div>
          <div className="thanks" style={style}>
            {people}
          </div>
        </div>
      ));
    }

    var detailedItem = null;
    if (this.state.showContact) {
      detailedItem = (
        <Overlay
          text={this.state.contact}
          title="Contact"
          closeDetail={this.closeDetail}
        />
      );
    }
    return (
      <Fragment>
      <Header
        page="team"
        showDetail={this.showDetail}
      />
      <div id="team-container" style={detailedItem ? {opacity: 0.25} : {}}>
        {detailedItem}
        <div id="title-section">
          <div className="first">
            Acknowledgements
          </div>
          <div className="second">
            WITH GREAT APPRECIATION TO THE FOLLOWING WHO HELPED MAKE THIS
            PROJECT POSSIBLE
          </div>
        </div>
        <div id="acknowledgements-container">
          {acknowledgements}
        </div>
        {loadingSection}
      </div>
      </Fragment>
    );
  }
}

// OLD LOGIN SYSTEM
// // password: TrackerImm2019!
// const condition = authUser => {
//   return (authUser && authUser.timelineAccess) || IsAuthenticated();
// }

const condition = authUser => {
  return (authUser && authUser.timelineAccess);
}
// protect Timeline with condition
export default withAuthorization(condition)(Team);
