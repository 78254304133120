import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CommonMark from 'commonmark';
import ReactRenderer from 'commonmark-react-renderer';

import './Overlay.css';

class Overlay extends Component {
  render() {
    var detailsContainer = null;
    if (this.props.title && this.props.title === "Contact") {
      detailsContainer = (
        <div className="detail-container">
          <div className="buttons-container">
            <Link to="/access" className="button">
              <div className="red"> REQUEST </div>
              <div className="yellow"> ACCESS </div>
            </Link>

            <Link to="/contact" className="button">
              <div className="red"> SUBMIT </div>
              <div className="yellow"> COMMENTS </div>
            </Link>
          </div>
        </div>
      );
    }
    else {
      const parser = new CommonMark.Parser();
      const renderer = new ReactRenderer({linkTarget: "_blank"});
      var ast = parser.parse(this.props.text);
      var text = renderer.render(ast);

      detailsContainer = (
        <div className="detail-container">
          <div className="text">
            {text}
          </div>
        </div>
      );
    }

    return (
      <div className="overlay-overlay">
        <div className="negative-space" onClick={this.props.closeDetail}></div>
        <div className="overlay">
          <div className="overlay-bg">
            <div className="return-container">
              <div className="return" onClick={this.props.closeDetail}>
                X
              </div>
            </div>
            <div className="title-container">
              <div className="title">
                {this.props.title}
              </div>
            </div>
            {detailsContainer}
          </div>
        </div>
      </div>
    );
  }
}

export default Overlay;
