/*
  functions used to fetch data for About Container
*/
import { parseCategories, FetchSheets } from './helpers';
import { VARIOUS_PAGE_DATA_INDEX, ABOUT_ROW } from './constants';
// fetch data for the about page
function FetchAbout(callback) {
  // fetch sheet 0
  // sheetsRes - response object
  FetchSheets([VARIOUS_PAGE_DATA_INDEX], (sheetsRes) => {
    callback(
      parseCategories(sheetsRes), // list of catgeories [label, color, description]
      sheetsRes.data.valueRanges[VARIOUS_PAGE_DATA_INDEX].values[ABOUT_ROW][3], // about page description
      sheetsRes.data.valueRanges[VARIOUS_PAGE_DATA_INDEX].values[ABOUT_ROW][4] // lucas bio
    );
  });
}

export default FetchAbout;
