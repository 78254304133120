// Library Imports
import React, { Component } from 'react';
import CommonMark from 'commonmark';
import ReactRenderer from 'commonmark-react-renderer';
// Function and Component Imports
import { FetchFooter } from 'services/fetchData';
//Style Imports
import './Footer.css';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parser: new CommonMark.Parser(),
      renderer: new ReactRenderer({linkTarget: "_blank"}),
    };
  }

  componentDidMount() {
    FetchFooter((text, text_size, text_style) => {
      this.setState({
        text: text,
        text_size: text_size,
        text_style: text_style
      });
    });
  }

  render() {
    var footer = null;
    if (this.state.text) {
      var temp = this.state.parser.parse(this.state.text);
      footer = this.state.renderer.render(temp);
    }
    var style = null;
    if (this.state.text_size || this.state.text_style) {
      style = {fontSize: `${this.state.text_size}px`, fontFamily: `${this.state.text_style}`};
    }
    return(
      <div id="footer">
        <hr id="footer-break"/>
        <div id="footer-text" style={style}>
          {footer}
        </div>
      </div>
    );
  }
}

export default Footer
