/*  constants.js
  Defines Constants that describe the inforation 
  structure of Final Data Page
*/

export const VARIOUS_PAGE_DATA_INDEX = 0;
export const NUM_CATEGORIES = 10;
export const TEAM_SHEET_INDEX = 11;
export const DEFAULT_COLOR = 'ffffff';

// VARIOUS_PAGE_DATA page constants
export const CATEGORIES_ROW = 1;
export const NO_ACCESS_ROW = 13;
export const ABOUT_ROW = 16;
export const LANDING_ROW = 17;
export const HOW_TO_ROW = 19;
export const FOOTER_ROW = 21;
export const TIMELINE_ROW = 23;

// Timeline Data Constants

export const DESCRIPTORS_INDEX = 0;
export const ANNOUNCED_DATE_I = 2;
export const EFFECTIVE_DATE_I = 3;
export const TITLE_I = 4;
export const POLICY_I = 5;
export const POLICY_DOC_I = 6;
export const TYPE_ACTION_I = 7;
export const AGENCIES_AFFECTED_I = 8;
export const PRIOR_POLICY_I = 9;
export const PRIOR_POLICY_DOC_I = 10;
export const SUBSEQUENT_ACTION_I = 11;
export const COMMENTARY_DOC_I = 12;
export const SEARCH_TERMS_I = 13;
export const REPORTED_I = 14;
export const PRIORITY_I = 15;

export const NEW_INDICIES = {
  'Links to Commentary ': COMMENTARY_DOC_I,
  'New Policy': POLICY_I,
  'Source Document': POLICY_DOC_I,
  'Subsequent Action Related to New Policy': SUBSEQUENT_ACTION_I,
  'Type of Action': TYPE_ACTION_I
}

export const OLD_INDICIES = {
  'Prior Policy': PRIOR_POLICY_I,
  'Prior Policy Source Document': PRIOR_POLICY_DOC_I,
}


