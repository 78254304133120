const config = {
  GOOGLE_SHEETS_API_KEY: "AIzaSyAcAvaptZsbB2gNi84AiYxf4B0Yy1X6lhw",
  WORKBOOK_ID: '1a8bOK4FQU_QDJR5IrcmSAWjCAGNutRb8G8PbNZaN5dU',// "1lmREpaM9afmcbq2jykEcTqtFoT9iEQRGS2eQWGfRstE",
  PASSWORD: "TrackerImm2019!",
  GMAIL_EMAIL: "TrackerImm.contact@gmail.com",
  GMAIL_PASSWORD: "TrackerFix2019!"
}

// old_WORKBOOK_ID: "1YB3dBEcgxrOyb58gw0nSGCWSrZCP_vvx9X9IW6iOGRI",

export default config;
