// Library Imports
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import CommonMark from 'commonmark';
import ReactRenderer from 'commonmark-react-renderer';
// Function and Component Imports
import { FetchLanding } from 'services/fetchData';
import Header from 'components/Header';
import Loading from 'components/Loading';
// Style Imports
import './Landing.css';

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parser: new CommonMark.Parser(),
      renderer: new ReactRenderer({linkTarget: '_blank'}),
      categories: {},
      showContact: false,
      hovered: null
    }

    this.mouseEntered = this.mouseEntered.bind(this);
    this.mouseExited = this.mouseExited.bind(this);
    this.closeDetail = this.closeDetail.bind(this);
    this.showDetail = this.showDetail.bind(this);
  }

  componentDidMount() {
    document.body.classList.toggle('dark', false);
    FetchLanding((categories, landingDescription, contact) => {
      // acknowledgements is an Obj with [[people], color]
      this.setState({
        categories: categories,
        landingDescription: landingDescription,
        contact: contact
      });
    });
  }

  // TO DO fix detail
  mouseEntered(e) {
    const bounding = e.target.getBoundingClientRect();
    const x = bounding.x + (bounding.width/2);
    const y = bounding.y + 20;
    
    this.setState({
      hovered: {
        index: e.target.name,
        info: this.state.categories[parseInt(e.target.name)],
        x: x,
        y: y
      }
    });
  }

  mouseExited(e) {
    this.setState({hovered: null});
  }

  closeDetail() {
    this.setState({showContact: false});
  }

  showDetail() {
    this.setState({showContact: false});
  }


  render() {
    var categories = [];
    for (var i = 0; i < this.state.categories.length; i++) {
      const category = this.state.categories[i];
      categories.push((
        <Link to={{pathname: '/timeline', state: { category: category}}} className='category' style={{background: '#' + category.color}}
              key={i} name={i} onMouseEnter={this.mouseEntered} onMouseLeave={this.mouseExited}>
          <div className='text'>
            {category.label.replace(/\//g, ' ')}
          </div>
        </Link>
      ));
    }

    if (categories.length === 0) {
      categories = <Loading />;
    }

    return (
      <Fragment>
        <Header page='landing'/>
        <div id='landing-container' className='page-container'>
          <div id='what-container' className='page-container'>
            <div className='question-mark-container'><p className='question-mark'>?</p></div>
            <h2 className='page-title content'>What?</h2>
            <p className='page-description content'>The Tracking Project catalogues every immigration policy change adopted or announced since 2017 with a link to the source document and any prior policy.</p>
            <Link to='/what' className='nav-link-bigger page-link'>Learn More</Link>
          </div>

          <div id='who-container' className='page-container'>
            <div className='question-mark-container'><p className='question-mark'>?</p></div>
            <h2 className='page-title content'>Who?</h2>
            <p className='page-description content'>The Tracking Project was begun in 2017 by Professor Lucas Guttentag working with teams of law students at Yale and Stanford Law Schools.</p>
            <Link to='/who' className='nav-link-bigger page-link'>Learn More</Link>
          </div>

          <div id='how-container' className='page-container'>
            <div className='question-mark-container'><p className='question-mark'>?</p></div>
            <h2 className='page-title content'>How?</h2>
            <p className='page-description content'>Register for access. Click above to enter Tracking Project.  Click 'Learn More' for instructions on navigating the website.</p>
            <Link to='/how' className='nav-link-bigger page-link'>Learn More</Link>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Landing;
