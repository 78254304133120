// Library Imports
import React, { Component, Fragment } from 'react';
// Media Imports
// Function and Component Imports
import Header from 'components/Header';
import Footer from 'components/Footer';
import TimelineController from './TimelineController';
import HowTo from './HowTo'
// import { IsAuthenticated } from 'services/auth'; // OLD LOGIN SYSTEM
import { withAuthorization } from 'services/session';
// Style Imports
import './TimelineContainer.css';
import 'styles/buttons.css';

class TimelineContainer extends Component {
  render() {
    return (
      <Fragment>
        <Header page="timeline"/>
        <TimelineController/>
        <HowTo/>
        <Footer/>
      </Fragment>
    );
  }
}

// OLD LOGIN SYSTEM
// // password: TrackerImm2019!
// const condition = authUser => {
//   return (authUser && authUser.timelineAccess) || IsAuthenticated();
// }

const condition = authUser => {
  return (authUser && authUser.timelineAccess);
}
// protect Timeline with condition
export default withAuthorization(condition)(TimelineContainer);
