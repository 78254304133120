/* helpers.js
  helper functions for fetchData
*/

import config from 'config.js';
import axios from 'axios';
import {VARIOUS_PAGE_DATA_INDEX, CATEGORIES_ROW, NUM_CATEGORIES } from './constants'

// parse list of categories [label, color, description] from sheetsRes
function parseCategories(sheetsRes) {
  let categories = [];
  for (let i = CATEGORIES_ROW + 1; i <= NUM_CATEGORIES + 1; i++) { // +1 because of all categories row
    categories.push({
      label: sheetsRes.data.valueRanges[VARIOUS_PAGE_DATA_INDEX].values[i][0],
      color: sheetsRes.data.valueRanges[VARIOUS_PAGE_DATA_INDEX].values[i][1],
      description: sheetsRes.data.valueRanges[VARIOUS_PAGE_DATA_INDEX].values[i][3],
    });
  }
  return categories;
}

/* Fetch Sheets
  Fetch the sheets specified in sheet and run the callback on them

  params:
    sheetsOfInterest - array of indicies of sheets to be retreived
    callback - function which is executed with the sheets as argument
  ret:
    calls callback on response object
*/
function FetchSheets(sheetsOfInterest, callback) {
  const baseURL = "https://sheets.googleapis.com/v4/spreadsheets/";
  let sheetsURL = baseURL + config.WORKBOOK_ID;
  sheetsURL += "?fields=sheets.properties&key=" + config.GOOGLE_SHEETS_API_KEY;
  // retreive sheets of spreadsheet
  axios.get(sheetsURL).then((data) => {
    var valuesURL = baseURL + config.WORKBOOK_ID + "/values:batchGet?";
    const sheetData = data.data.sheets.slice(0, data.data.sheets.length);
    for (var i = 0; i < sheetsOfInterest.length; i++) {
      var sheetTitle = sheetData[sheetsOfInterest[i]].properties.title.replace(/ /g, "%20").replace(/\//g, "%2F");
      valuesURL += "ranges=" + sheetTitle + "!A1%3AZ999&";
    }
    valuesURL = valuesURL.substring(0, valuesURL.length-1);
    valuesURL += "&key=" + config.GOOGLE_SHEETS_API_KEY;
    axios.get(valuesURL).then((res) => { callback(res); });
  });
}

export { parseCategories, FetchSheets };
