// Library Imports
import React, { Component, Fragment } from 'react';
// Function and Component Imports
import Header from 'components/Header';
import { withAuthorization } from 'services/session';
import { FirebaseContext } from 'services/firebase';
// Style Imports
import '../Admin/Admin.css';

// List of users saved in the firebase databse
class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = { users: null };
  }

  // on mount, load user list
  componentDidMount() {
    this.props.firebase.users().on('value', (snapshot) => {
      this.setState({ users: Object.entries(snapshot.val()) });
    });
  }

  // on unmount, turn off reference
  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  // build a button which preforms a firebase update
  // args:
  //  text - text to display
  //  uid - uid of updated user
  //  update - the update to be preformed
  buildButton = (uid, text, update) => {
    return (
      <button
        onClick={
          () => { this.props.firebase.user(uid).update(update); }
        }>
        {text}
      </button>
    );
  }

  // build button corresponding to
  buildButtons = (user, uid) => {
    if (user.dev) {
      return [
        this.buildButton(uid, 'Revoke Dev', {dev: false})
      ];
    } else if (user.admin) {
      return [
        this.buildButton(uid, 'Revoke Admin', {admin: false}),
        this.buildButton(uid, 'Grant Dev', {dev: true})
      ];
    } else if (user.timelineAccess) {
      return [
        this.buildButton(uid, 'Revoke Access', {timelineAccess: false}),
        this.buildButton(uid, 'Grant Admin', {admin: true})
      ];
    } else { // user has no access
      return [
        this.buildButton(uid, 'Grant Access', {timelineAccess: true})
      ];
    }
  }

  buildUserLists = () => {
    let dev = [], admin = [], accessGranted = [], pendingAccess = [];
    if (this.state.users) {
      for (let i = 0; i < this.state.users.length; i++) {
        let user = this.state.users[i][1], uid = this.state.users[i][0];
        let entry = (
          <div className="user-entry" key={uid}>
            <p className="name">{user.firstName + ' ' + user.lastName}</p>
            <p className="affiliation">{user.affiliation}</p>
            <p className="email">{user.email}</p>
            {this.buildButtons(user, uid)}
          </div>
        );
        if      (user.dev)            { dev.push(entry)}
        else if (user.admin)          { admin.push(entry);         }
        else if (user.timelineAccess) { accessGranted.push(entry); }
        else   /*user has no access */{ pendingAccess.push(entry); }
      }
    }
    return [dev, admin, accessGranted, pendingAccess]
  }

  render() {
    let [dev, admin, accessGranted, pendingAccess] = this.buildUserLists();
    return (
      <div id="user-list-container">
        <div className="user-list" id="pending-list">
          <h2 className="list-title">Pending Access</h2>
          {pendingAccess}
        </div>
        <div className="user-list" id="access-list">
          <h2 className="list-title">Access Granted</h2>
          {accessGranted}
        </div>
        <div className="user-list" id="admin-list">
          <h2 className="list-title">Admin</h2>
          {admin}
          <h2 className="list-title">Dev</h2>
          {dev}
        </div>
      </div>
    );
  }
}

class Dev extends Component {
  render() {
    return (
      <Fragment>
        <Header page="dev"/>
        <FirebaseContext.Consumer>
          {firebase => <UserList firebase={firebase}/>}
        </FirebaseContext.Consumer>
      </Fragment>
    );
  }
}

// only admin can access
const condition = authUser => {
  return (authUser && authUser.dev);
}
export default withAuthorization(condition)(Dev);
