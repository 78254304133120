// Library Imports
import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom'
// Function and Component Imports
import Header from 'components/Header';
import { SubmitComment } from 'services/sendData';
import { CleanText } from 'services/helpers.js';
// import { IsAuthenticated } from 'services/auth'; // OLD LOGIN SYSTEM
import { withAuthorization } from 'services/session';
import { AuthUserContext } from 'services/session';
// Style Imports
import "./Contact.css";

class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      subject: '',
      comment: '',
      type: 'General Comment',
      entry: '',
    }
  }

  /*
    checks URL for if it contains an entry query. If so update this.state.type
    to reflect it
  */
  componentDidMount() {
    let url = window.location.href;
    let regex = /https?:\/\/.*\/comment\?entry=(.*)\/\?date=(.*)\/\?category=(.*)\//gmi
    let match = regex.exec(url);
    if (match && match[1] && match[2] && match[3]) {
      this.setState({
        type: 'Comment Concerning Entry entitled: \n' + this.encodeHTML(decodeURI(match[1])),
        entry_title: decodeURI(match[1]),
        entry_date: parseInt(decodeURI(match[2])),
        entry_category: decodeURI(match[3])
      });
    }
  }

  encodeHTML = (s) => {
    return s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/"/g, '&quot;');
  };

  /* handle change of form fields
  args:
    e - event object
  */
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  // TODO: IMPROVE THIS TO CATCH ERRORS
  checkFields = (field) => {
    var text = CleanText(this.state[field]);
    if (text === "") {
      this.setState({message: 'Please fill in a ' + field});
      return null;
    }
    return text;
  };

  buildComment = (comment, user) => {
    let entry_desc = 
      this.state.entry_title? 
      "Comment concerning entry entitled:\n " + this.state.entry_title + "\n":
      "General Comment\n";
    const options = { day: 'numeric', month: 'long', year: 'numeric'};
    const date = new Date(this.state.entry_date);
    console.log(date);
    let entry_info = 
      "Category: " + this.state.entry_category + "\n" +
      "Date: " + date.toLocaleDateString("en-US", options) + "\n\n";
    let commenter = 
      "Commenter: \n " + user.firstName + " " + user.lastName + "\n" + 
      " of: " + user.affiliation + "\n" +
      " email: " + user.email + "\n\n";
    let commentHeader = "Comment:\n";
    return entry_desc + entry_info + commenter + commentHeader + comment;
  };

  submit = e => {
    e.preventDefault();
    let comment = this.checkFields('comment');
    let subject = this.checkFields('subject');
    if (comment && subject) {
      SubmitComment(
        "COMMENT: " + subject, // subject
        this.buildComment(comment, this.props.authUser), // body text
        (response) => {
          alert("Thank you for your Comment!");
          this.setState({redirect: true})
        });
    }
  };

  render() {
    // redirect to home if commented
    if (this.state.redirect) { return <Redirect to='/' />; }
    return (
      <div id="form-container">
        <div id='form-title' className="explanation title">{this.state.type}</div> 
        <hr id='form-title-hr'/> 
        <div className="explanation">{this.state.message}</div>
        <form id="comment-form">
          <input type="text" id="subject" name="subject" placeholder="Subject" onChange={this.onChange}/>
          <textarea type="text" id="comment" name="comment" placeholder="Comment" onChange={this.onChange}/>
          <button type="submit" className="nav-button" onClick={this.submit}>Submit</button>
        </form>
      </div>
    );
  }
}

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Submit a Comment",
      subtitle: "Please complete the following to submit feedback for an entry",
      subject: "Specific Feedback/Comments Submitted",
    }
  }

  render() {
    return (
      <Fragment>
        <Header page="contact"/>
        <div id="contact-container">
          <div id="title-container">
            <div id="title" className='title'>{this.state.title}</div>
            <div id="subtitle">{this.state.subtitle}</div>
          </div>
          <AuthUserContext.Consumer>
            {authUser => <CommentForm authUser={authUser} page={this.props.page}/>}
          </AuthUserContext.Consumer>
        </div>
      </Fragment>
    );
  }
}

// OLD LOGIN SYSTEM
// // password: TrackerImm2019!
// const condition = authUser => {
//   return (authUser && authUser.timelineAccess) || IsAuthenticated();
// }

const condition = authUser => {
  return (authUser && authUser.timelineAccess);
}
// protect Timeline with condition
export default withAuthorization(condition)(Contact);
