// Library Imports
import React, { Component, Fragment } from 'react';
// Media Imports
import search from 'images/search.png';
import close from 'images/close.png';
// Function and Component Imports
import { FetchTimeline } from 'services/fetchData';
// import TimelineDrawing from './TimelineDrawing';
import Timeline from './Timeline';
import FilterDropdown from './FilterDropdown';
// import ListView from './ListView';
import Loading from 'components/Loading';
import InfoPopUp from 'components/InfoPopUp';
// Style Imports

class TimelineController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Represents filter state
      filter: {
        show: false,
        start: "",
        end: "",
        category: "- SELECT CATEGORY -",
        agency: "- SELECT AGENCY -",
        showPriority1: true,
        showPriority2: true,
        showPriority3: true,
        showPriorityUndef: true,
      },
      // Represents search state
      search: {
        text: "",
        searched: false,
      },
      // Represents reported checkboxes states
      onlyReported: false,
      showReported: false,
      onlyCovid: false
    };
  }

  // Fetch Date on Mount
  componentDidMount() {
    /* Fetches categories, agencies, orderedData from Google Sheets
      categories - list of category objects
        category obj - label - name of catgeory
                       description - description
                       color - color
                       entries - associated entries
      agencies - list of mentioned agencies
      ordered data - list of entries ordered by date
    */
    FetchTimeline((categories, agencies, orderedData, searchInfoPopUp, reportedPopUp, upperBoxLabel, lowerBoxLabel, covidBoxLabel) => {
      agencies.unshift("- SELECT AGENCY -");
      this.setState({
        /* allData represents parsed data (categories) returned from Fetch
          allData = [{label: str, description: str, color: str, entries: [Entries]}]
          includes allCatgegories category at 0th index */
        allData: categories,
        /* filteredData represents data that is being shown to the user
          allData = [{label: str, description: str, color: str, entries: [Entries]}] */
        filteredData: categories,
        /* agencies, list of agencies that appear in entries
          agencies = [str] */
        agencies: agencies,
        /* orderedData, list of Entries ordered by Date
          orderedData = [str] */
        orderedData: orderedData,
        // Represents selected entry
        selectedItem: {
          data: orderedData[0],
          category: orderedData[0].category,
          orderedIndex: 0,
          scrollTo: true
        },
        searchInfoPopUp: searchInfoPopUp,
        reportedPopUp: reportedPopUp,
        upperBoxLabel: upperBoxLabel,
        lowerBoxLabel: lowerBoxLabel,
        covidBoxLabel: covidBoxLabel,
      });
    });
  }

  /* SEARCH */

  // on change of search text
  onSearchChange = (e) => {
    this.setState({search: {
      ...this.state.search,
      searched: false,
      text: e.target.value
    }});
  };

  // check if the passed expression is a valid boolean expression
  // expects form e.g. (1 || 0) && 1
  validateBooleanExp = (expression) => {
    var pattern = /(?:^ *)?(?:\( *|())\w+(?: +(?:&&|\|\|) +\w+)?(?: *\)|\1)/g;
    // recursively break down pattern expression into valid components
    while (true) {
      var replaced = expression.replace(pattern, '1');
      // if the expression has been reduced to '1', it's valid
      if (replaced === '1') return true;
      // if the pattern can't break down further, it's invalid
      if (replaced === expression) return false;
      expression = replaced;
      }
  };

  /* returns true if boolean expression matches text and false otherwise
    assumes valid booleanExp
  */
  booleanExpressionMatch = (booleanExp, text) => {
    // replace searched words with boolean representing presence
    booleanExp = booleanExp.replace(/[\w-]+/g, (match) => {
      return (new RegExp(match, 'i')).test(text)? '1': '0';
    }).replace(/ /g, ''); // remove spaces
    let andOrFinder = /(?:\(|())([01](&&|\|\|)[01])(?:\)|\1)/g;
    // recursively reduce expression at && and || junctions
    while (true) {
      let replaced = booleanExp.replace(andOrFinder, (match, p1, boolean) => {
        return ['0||1', '1||0', '1||1', '1&&1'].includes(boolean)? '1': '0';
      });
      // if the expression has been reduced to '1', it's valid
      if (replaced === '1') return true;
      // if the pattern can't break down further or is '0', it's invalid
      if (replaced === booleanExp || replaced === '0') return false;
      booleanExp = replaced;
    }
  };

  /* Returns cleaned search text
      cleaning - replace special terms with special characters
                 trim out unneeded white space
  */
  cleanSearchText = (text) => {
    // booleanExp = booleanExp.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // escape special chars
    return text.trim().replace(/AND/g, '&&').replace(/OR/g, '||');
  };

  /* takes non-boolean expression and makes boolean
    assumes all terms in searchText are being searched and place && between each
  */
  makeBooleanExp = (text) => {
    // regex to find words which are not the first word
    return text.replace(/( \w+)/g, ' &&$1');
  };

  /* Filter entries based on search text
    search text is turned into regexes through getRegexes
    entries then added to state.filteredData and orderedData only if the entries
    match the regex.
  */
  search = (e) => {
    e.preventDefault();
    // check if clicked close button
    if (this.state.search.searched) {
      this.setState({
        filteredData: this.state.allData,
        search: {text: '', searched: false}
      });
      return;
    }
    // clean search text and then search
    let searchText = this.cleanSearchText(this.state.search.text);
    // Check if search text is valid boolean expression
    if (!this.validateBooleanExp(searchText)) {
      // if not boolean expression and there are no boolean terms
      // assume all terms in searchText are being searched and place && between each
      searchText = this.makeBooleanExp(searchText);
    };
    let filtered = [], orderedData = [];
    // start filter at index 1 to jump over all categories
    for (let i = 1; i < this.state.allData.length; i++) {
      let entries = [], category = this.state.allData[i];
      category.entries.forEach((entry) => {
        // add entry if it matches boolean expression
        if (this.booleanExpressionMatch(searchText, entry.title + ' ' + entry.description + entry.searchTerms)) {
          entries.push(entry);
          orderedData.push(entry);
        }
      });
      filtered.push({
        label: category.label, description: category.description,
        color: category.color, entries: entries
      });
    }
    filtered.unshift({
      label: "All categories", description: "All the categories.",
      color: this.state.allData[0].color, entries: orderedData,
    });
    this.setState({
      filteredData: filtered,
      orderedData: orderedData,
      search: {...this.state.search, searched: true}
    });
  }

  /* FILTER */

  // toggle filter show
  toggleFilter = (val) => {
    if (this.state.filter.show !== val) {
      this.setState({ filter: {
        ...this.state.filter,
        show: val
      }});
    }
  }

  /* Takes a data Object as an argument and returns a filtered data argument
    Data is filtered based on the state of the filter:
      category - catgeories to be shown
      agency - agencies to be shown
      start - start date of entries to be shown
      end - end date of entries to be shown
  */
  applyFilter = data => {
    let start = (this.state.filter.start !== "")? (new Date(this.state.filter.start)).getTime(): null;
    let end   = (this.state.filter.end !== "")? (new Date(this.state.filter.end)).getTime(): null;
    let filtered = [], orderedData = [];
    // start filter at index 1 to jump over all categories
    for (let i = 1; i < data.length; i++) {
      // add empty category if category is filtered out
      if (this.state.filter.category !== "- SELECT CATEGORY -" && this.state.filter.category !== data[i].label) {
        let category = data[i];
        filtered.push({
          label: category.label, description: category.description,
          color: category.color, entries: []
        });
        continue;
      }
      // add category with entries filtered by date and agency and priority
      var category = data[i];
      var d = { label: category.label, description: category.description,
                color: category.color, entries: [] };
      // iteratre through entries to find those to be added
      for (var j = 0; j < category.entries.length; j++) {
        if (!category.entries[j]) { continue; }
        let entry = category.entries[j];
        // Check date 
        if (start || end) {
          if ((start && start > entry.date) || (end   && end   < entry.date)) {
            continue;
          }
        }
        // Check priority
        /*
        if ((entry.priority && !this.state.filter['showPriority' + entry.priority]) ||
            (!entry.priority && !this.state.filter['showPriorityUndef'])) {
          continue
        }*/
        // Check if match agency
        if (this.state.filter.agency !== "- SELECT AGENCY -") {
          const agencyAffected = category.entries[j].agencyAffected;
          if (agencyAffected && agencyAffected.length > 0) {
            for (var k = 0; k < agencyAffected.length; k++) {
              if (this.state.filter.agency === agencyAffected[k]) {
                orderedData.push(category.entries[j]);
                d.entries.push(category.entries[j]);
                break;
              }
            }
          }
        } else {
          orderedData.push(category.entries[j]);
          d.entries.push(category.entries[j]);
        }
      }
      filtered.push(d);
    }
    // unshift All Categories
    filtered.unshift({
      color: this.state.allData[0].color,
      label: "All categories",
      entries: orderedData,
      description: "All the categories."
    });
    // this.setState({
    //   filter: {
    //     ...this.state.filter,
    //     show: false,
    //   },
    //   filteredData: filtered,
    //   orderedData: orderedData,
    //   selectedItem: {
    //     data: orderedData,
    //     category: (orderedData && orderedData[0])? orderedData[0].category: null,
    //     orderedIndex: 0,
    //     scrollTo: true
    //   }
    // });
    return filtered;
  };

  /* PROPSED CHECK BOXES */

  // on check of checkbox
  onCheck = event => {
     this.setState({
       [event.target.name]: document.getElementById(event.target.name).checked
     });
   }

   // filter data based on state of proposed check boxes
  filterProposed = data => {
    let res = [];
    for (let i = 0; i < data.length; i++) {
      res.push({
        color: data[i].color,
        description: data[i].description,
        label: data[i].label,
        entries: [],
      });
      for (let e = 0; e < data[i].entries.length; e++) {
        let entry = data[i].entries[e];
        if (entry.reported) {
          if (this.state.showReported) res[i].entries.push(entry);
        } else {
          if (!this.state.onlyReported) res[i].entries.push(entry);
        }
      }
    }
    return res;
  }
  
  // filters out all entries from data that don't mention COVID-19
  filterCovid = data => {
    let searchText = 'covid || coronavirus';
    data.forEach(category => {
      category.entries = category.entries.filter(entry => {
        if (this.booleanExpressionMatch(
          searchText, 
          entry.title+' '+entry.description+entry.searchTerms))
            return true;
        return false;
      });
    });
  }

  render() {
    // if no filtered data, present loading
    if (!this.state.filteredData) { return <Loading />; }
    let data = this.applyFilter(this.filterProposed(this.state.filteredData));
    if (this.state.onlyCovid) {
      this.filterCovid(data);
    }
    let filterDropdown = null;
    if (this.state.filter.show) {
      filterDropdown = (
        <FilterDropdown
          allData={this.state.allData}
          agencies={this.state.agencies}
          filter={this.state.filter}
          toggleFilter={this.toggleFilter}
          setFilter={filterState => this.setState({filter: filterState})}
          />
      );
    }

    let covidAlert = (
      <form id="covid-toggle">
        <div id="only-covid-container">
          <p id="only-covid-warning">⚠️</p>
          <p id="only-covid-label">{this.state.covidBoxLabel}</p>
          <input
            type="checkbox"
            name="onlyCovid"
            id="onlyCovid"
            checked={this.state.onlyCovid}
            onChange={this.onCheck}
            />
        </div>
      </form>
    );

    let timelineControls = (
      <div id="timeline-controls" style={false? {opacity: 0.25} : {}}>
        <form id="search" onSubmit={e => this.search(e)}>
          <input
            type="text"
            className="form-control form-control-lg"
            placeholder="Search for Keywords"
            value={this.state.search.text}
            onChange={this.onSearchChange}
            />
          <button type="submit" className="nav-button">
            <img src={this.state.search.searched? close: search} alt="search" />
          </button>
        </form>
        <InfoPopUp text={this.state.searchInfoPopUp}/>
        <div id="filter-button" className="nav-link" onClick={() => this.toggleFilter(true)}>
          Filter 
          {filterDropdown}
        </div>
        <form id="reported-toggle">
          <div id="include-reported">
            <input
              type="checkbox"
              name="showReported"
              id="showReported"
              checked={this.state.showReported}
              onChange={this.onCheck}
              />
            {this.state.upperBoxLabel}
          </div>
          <div id="only-reported">
            <input
              type="checkbox"
              name="onlyReported"
              id="onlyReported"
              checked={this.state.onlyReported}
              onChange={this.onCheck}
              />
            {this.state.lowerBoxLabel}
          </div>
        </form>
        <InfoPopUp text={this.state.reportedPopUp}/>
        {covidAlert}
      </div>
    );

    /*   ADD BACK IN LATER
          <Dashboard
          data={data}
          />
    */

    return (
      <Fragment>
        {timelineControls}
        <Timeline
          data={data}
          orderedData={this.state.orderedData}
          height={this.state.height}
          />
      </Fragment>
    );
  }
}

export default TimelineController;
