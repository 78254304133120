import React, { Component } from 'react';
import { Link } from "react-router-dom";
import * as d3 from 'd3';
import CommonMark from 'commonmark';
import ReactRenderer from 'commonmark-react-renderer';
import { HexToRgb } from 'services/helpers.js';

import './Detail.css';

class Detail extends Component {
  constructor(props) {
    super(props);
    this.parser = new CommonMark.Parser();
    this.renderer = new ReactRenderer({linkTarget: "_blank"});
  }

  createSection = (data, backgroundColor, addCategory=false) => {
    var container = null;
    if (data) {
      var sections = [];
      var entries = Object.entries(data);

      if (addCategory) {
        sections.push((
          <div className="section" key={-1}>
            <div className="header">
              CATEGORY
            </div>
            <div className="information">
              {this.props.item.category.label}
            </div>
          </div>
        ));
      }

      for (var i = 0; i < entries.length; i++) {
        var info = entries[i][1];
        if (info) {
          var ast = this.parser.parse(info);
          info = this.renderer.render(ast);
          sections.push(
            <div className="section" key={i}>
              <div className="header">
                {entries[i][0].toUpperCase()}
              </div>
              <div className="information">
                {info}
              </div>
            </div>
          );
        }
      }

      if (sections.length > 0) {
        container = (
          <div className="policy-container">
            {sections}
          </div>
        );
      }
    }
    return container;
  }

  createTitle = (bg1) => {
    const title = this.renderer.render(this.parser.parse(this.props.item.data.title));
    const d = new Date(this.props.item.data.date);
    const dateStr = (d3.timeFormat("%B %d, %Y")(d)).toUpperCase();
    return (
      <div id="title-container">
        <div id="title" className="title">{title}</div>
        <div id="date">
          <hr/>
          {dateStr}
          <hr/>
        </div>
      </div>
    );
  }

  render() {
    const rawColor = this.props.item.category.color;
    const rgb = HexToRgb("#"+rawColor);

    const bg1 = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5)`;
    const bg2 = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.75)`;

    // 
    const entry = this.props.item.data;

    const newSection = this.createSection(entry.new, bg1, true);
    const oldSection = this.createSection(entry.old, bg2);

    return (
      <div id="detail-overlay">
        <div id="negative-space" onClick={this.props.closeDetail}></div>
        <div id="detail">
        <div id="detail-bg">
          <div id="return-container">
            <div id="return" onClick={this.props.closeDetail}>X</div>
          </div>
          {this.createTitle(bg1)}
          <div id="detail-container">
            <div id="feedback-container" className="title">
              <p>
                Please submit general feedback/corrections/comments
                on this policy
              <span>
              <Link
                to={
                  "/comment?entry=" + encodeURI(entry.title) + 
                  "/?date=" + encodeURI(entry.date) +
                  "/?category=" + encodeURI(entry.category.label) }
                target="_blank" rel="noopener noreferrer" id="link-wrapper">
                Here
              </Link>
              </span>
              </p>
            </div>
            {newSection}
            {oldSection}
          </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Detail;
