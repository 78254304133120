// Library Imports
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import CommonMark from 'commonmark';
import ReactRenderer from 'commonmark-react-renderer';
// Function and Component Imports
import Header from 'components/Header';
import Overlay from 'components/Overlay';
import Footer from 'components/Footer';
import Loading from 'components/Loading';
import { FetchAbout } from 'services/fetchData';
// import { IsAuthenticated } from 'services/auth'; // OLD LOGIN SYSTEM
import { withAuthorization } from 'services/session';
// Style Imports
import "./About.css";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parser: new CommonMark.Parser(),
      renderer: new ReactRenderer({linkTarget: "_blank"}),
      aboutDescription: "",
      showContact: false,
      showLucas: false,
      categories: {}
    }

    this.closeDetail = this.closeDetail.bind(this);
    this.showDetail = this.showDetail.bind(this);
    this.showLucas = this.showLucas.bind(this);
    this.hideLucas = this.hideLucas.bind(this);
  }

  componentDidMount() {
    document.body.classList.toggle('dark', false);
    FetchAbout((categories, about, lucasBio, contact) => {
      // acknowledgements is an Obj with [[people], color]
      this.setState({
        categories: categories,
        aboutDescription: about,
        lucasDescription: lucasBio,
        contact: contact
      });
    });
  }

  showLucas() {
    this.setState({showLucas: true});
  }

  hideLucas() {
    this.setState({showLucas: false});
  }

  closeDetail() {
    this.setState({showContact: false});
  }

  showDetail() {
    this.setState({showContact: true});
  }

  render() {
    var categories = null;
    var timelineButton = null;

    var loadingSection = null;
    if (Object.keys(this.state.categories).length === 0) {
      loadingSection = <Loading />;
    }
    else {
      categories = [];

      timelineButton = (
        <Link to="/timeline" className="button">
          Enter Here
        </Link>
      );
    }

    for (var i = 0; i < this.state.categories.length; i++) {
      const category = this.state.categories[i];
      const style = {border: `3px solid #${category.color}`};

      if (category.description) {
        categories.push((
          <div className="category" key={i}>
            <Link to={{pathname: "/timeline", state: { category: category}}} className="title">
              {category.label.replace(/\//g, " · ")}
            </Link>
            <div className="text" style={style}>
              {category.description}
            </div>
          </div>
        ));
      }
    }

    var about = null;
    if (this.state.aboutDescription) {
      var desc = this.state.aboutDescription;
      var ast = this.state.parser.parse(desc);
      var aboutSections = this.state.renderer.render(ast);
      console.log(aboutSections);
      about = (
        <div id="description-contents">
          {aboutSections}
          <p>
            -
            <span className="link" onClick={this.showLucas}>Lucas Guttentag</span> and
            <Link to="/team" className="link">team</Link>
          </p>
        </div>
      );
    }

    var detailedItem = null;
    if (this.state.showLucas) {
      detailedItem = (
        <Overlay
          text={this.state.lucasDescription}
          title="Lucas Guttentag"
          closeDetail={this.hideLucas}
        />
      );
    }
    if (this.state.showContact) {
      detailedItem = (
        <Overlay
          text={this.state.contact}
          title="Contact"
          closeDetail={this.closeDetail}
        />
      );
    }

    return (
      <Fragment>
      <Header
        page="about"
        showDetail={this.showDetail}
      />
      {detailedItem}
      <div id="about-container" style={detailedItem ? {opacity: 0.25} : {}}>
        <div id="about-description">
          <div id="description-title" className="about-title">
            <span className="title-font title-italic black">About</span>
          </div>
          {about}
        </div>
        <div id="about-categories">
          <div id="categories-title" className="about-title">
            <span className="title-font title-italic black">Timeline</span>
            <span className="title-font yellow">Categories</span>
          </div>
          <div id="categories-contents">
            {timelineButton}
            {categories}
          </div>
        </div>
      </div>
      {loadingSection}
      <Footer/>
      </Fragment>
    );
  }
}


// OLD LOGIN SYSTEM
// // password: TrackerImm2019!
// const condition = authUser => {
//   return (authUser && authUser.timelineAccess) || IsAuthenticated();
// }

const condition = authUser => {
  return (authUser && authUser.timelineAccess);
}
// protect Timeline with condition
export default withAuthorization(condition)(About);
