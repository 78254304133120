import React from 'react';
import { withFirebase } from 'services/firebase';
import 'styles/buttons.css';

const SignOutButton = ({firebase}) => (
  <button type="button" className="nav-button" onClick={firebase.doSignOut}>
    Sign Out
  </button>
)

export default withFirebase(SignOutButton);
