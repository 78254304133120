// Library Imports
import React, {Component} from 'react';
import * as d3 from 'd3';
// Style Imports
import '.././Timeline.css';

const itemHeight = 36, itemMargin = 3;
let entryRadius = (itemHeight / 12);

class TimelineDrawing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beginning: null,
      ending: null,
      zoom: null,
      zoomLevels: [
        // 1w, 1m, 3m, 6m, 1yr, 3yr, max
        604800000, 2592000000, 7889238000, 15778476000, 31556952000, 94670856000, "max"
      ],
    }
  }

  componentDidMount() { this.drawTimeline(); }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) { this.drawTimeline(); }
  }

  // add x axis, date axis, to the timeline
  buildAxis = (beginning, ending, margin) => {
    let xScale = d3.scaleTime()
      .range([margin.left, this.props.width - margin.right])
      .domain([beginning, ending]);//.nice();
    let tickFormat = {
      format: d3.timeFormat("%-b %-d"),
      tickTime: d3.timeMonth,
      tickInterval: 0,
      tickSize: 0,
      tickValues: null
    };
    let yearTickFormat = {
      format: d3.timeFormat("%Y"),
      tickTime: d3.timeYear,
      tickInterval: 3,
      tickSize: 8,
    }
    // // TODO FIX THIS. THIS IS A DUCTAPE FIX
    // if (level == "max") {
    //   let trumpInaug = new Date('January 20, 2017');
    //   beginning = trumpInaug.getTime();
    //   d3.timeInterval(
    //     // floor
    //     (date) => {
    //       let day = new Date(date);
    //       day = day.getTime();
    //     },
    //     // offset
    //     () => {}
    //   );
    // }
    let dateAxis = d3.axisTop()
      .scale(xScale)
      .tickSize(tickFormat.tickSize)
      .tickFormat(tickFormat.format)
    let yearAxis = d3.axisTop()
      .scale(xScale)
      .tickSize(yearTickFormat.tickSize)
      .tickFormat(yearTickFormat.format)
    this.svg.append("g")
      .attr("class", "x-axis")
      .attr("transform", "translate(" + 0 + "," + margin.top + ")")
      .call(dateAxis);
    this.svg.append("g")
      .attr("class", "year-axis")
      .attr("transform", "translate(" + 0 + "," + (margin.top+22) + ")")
      .call(yearAxis);
  }

  // parse entries to find the beinning and ending time to be diplayed on timeline
  getRange = () => {
    let timeRange = {min: null, max: null};
    var yAxisMapping = {}, maxStack = 0;
    this.svg.selectAll("svg")
      .data(this.props.data).enter()
      .each((d, i) => {
        // create y mapping for stacked graph
        if (Object.keys(yAxisMapping).indexOf(i) === -1) {
          yAxisMapping[i] = maxStack;
          maxStack++;
        }
        d.entries.forEach((entry, index) => {
          // figure out beginning and ending times if they are unspecified
          if (entry.date < timeRange.min || (!timeRange.min)) { timeRange.min = entry.date; }
          if (entry.date > timeRange.max || (!timeRange.max)) { timeRange.max = entry.date; }
        });
      });
    // console.log(yAxisMapping); TODO figure out what this is
    timeRange.diff = timeRange.max - timeRange.min;
    var level = this.state.zoomLevels[this.props.zoom];
    var beginning = this.state.beginning, ending = this.state.ending;
    if (!beginning || !ending) { // no prior state to zoom into
      beginning = timeRange.max - level;
      if (beginning < timeRange.min) { beginning = timeRange.min; }
      ending = timeRange.max;
    } else {  // zoom into middle of prior state
      const middle = (beginning + ending) / 2;
      var tempBeginning = middle - (level / 2),
          tempEnding    = middle + (level / 2);
      // check if time scale is greater than range
      if (level === "max" || tempEnding - tempBeginning > timeRange.diff) {
        beginning = timeRange.min;
        ending    = timeRange.max;
        level = "max";
      } else {
        beginning = tempBeginning;
        ending    =  tempEnding;
      }
    }
    this.setState({
      beginning: beginning,
      ending: ending
    });
    // TODO fix this hack for if there is one elem
    if (beginning && beginning === ending) {
      beginning -= 1;
      ending += 1;
    }
    return [beginning, ending, yAxisMapping];
  }

  drawTimeline = () => {
    // setup tooltip and svg
    var tooltip = d3.select(this.refs.tooltip);
    tooltip.style("opacity", 0);
    if(!this.svg) {
      this.svg = d3.select("#" + this.props.id).append("svg");
      this.svg.attr("width", this.props.width).attr("height", this.props.height);
    } else {
      this.svg.attr("width", this.props.width).attr("height", this.props.height);
      this.svg.selectAll("*").remove();
    }

    let [beginning, ending, yAxisMapping] = this.getRange();
    var margin = {left: 15, right: 20, top: 20, bottom: 30};
    
    var scaleFactor = (1 / (ending - beginning)) * (this.props.width - margin.left - margin.right);
    var translate = ((this.props.width - margin.left - margin.right) / 10) * this.props.yTranslate;
    translate /= scaleFactor;
    const extraTime = margin.left / scaleFactor;
    beginning = beginning - extraTime + translate;
    ending = ending + extraTime + translate;
    this.buildAxis(beginning, ending, margin);
    scaleFactor = (1.0/(ending - beginning)) * (this.props.width - margin.left - margin.right)

    this.svg.selectAll("svg")
      .data(this.props.data).enter()
      // build out category rects
      .each((datum, index) => {
        var entries = datum.entries;
        this.svg.append("rect")
          .attr("x", (d, i) => 0)
          .attr("y", (d, i) => 20.5+margin.top+(itemHeight+itemMargin)*yAxisMapping[index]+itemHeight/2+0.3)
          .attr("width", (d, i) => this.props.width - margin.right)
          .attr("height", (d, i) => itemHeight)
          .attr("fill", (d, i) => { if (datum.color) return "#"+datum.color; })
          .attr("class", "rectangle");
        // build out entry dots
        this.svg.selectAll("svg")
          .data(entries).enter()
          .append((d, i) => {
              return document.createElementNS(d3.namespaces.svg, "display" in d? d.display:"circle");
          })
          .attr("x", (d, i) => {
            if (d.date - beginning < 0) { return -100; }
            if (d.date - ending > 0) { return 10000; }
            return margin.left + (d.date - beginning) * scaleFactor;
          })
          .attr("y", (d, i) => {
            return margin.top + (itemHeight + itemMargin) * yAxisMapping[index];
          })
          .attr("width", (d, i) => 100)
          .attr("cy", (d, i) => 20.5+margin.top+(itemHeight+itemMargin)*(yAxisMapping[index]+0.5)+itemHeight/2+0.3)
          .attr("cx", (d, i) => {
            if (d.date - beginning < 0) { return -100; }
            if (d.date - ending > 0) { return 10000; }
            return margin.left + (d.date - beginning) * scaleFactor;
          })
          .attr("r", (d, i) => {
            // Make the dot bigger if it is selected
            // if(that.props.selectedItem) {
            //   const item = that.props.selectedItem;
            //   if (item.category.label === datum.label && item.data.title === d.title && item.data.date === d.date) {
            //     return (itemHeight/2.0)/2;
            //   }
            // }
            // scale radius with respect to zoom level
            return (itemHeight / (Math.log(this.props.zoom + 2) * 3)) / 2;
          })
          .attr("height", (d, i) => {
            if(this.props.selectedItem) {
              const item = this.props.selectedItem;
              // console.log(item);
              if (item.category.label === datum.label && item.data.title === d.title && item.data.date === d.date) {
                return itemHeight/1.1;
              }
            }
            return itemHeight/2.0;
          })
          .style("fill", (d, i) => {
            // if (d.color) return d.color;
            return "black";
          })
          .on("mouseover", (d, i) => {
            let timeline = document.getElementById('timeline-container');
            if (!timeline) { return; }
            tooltip.transition()
              .duration(200)
              .style("opacity", .9);
            tooltip.html(d.title)
              .style("left", (d3.event.pageX + 8 - timeline.offsetLeft) + "px")
              .style("top", (d3.event.pageY - 12 - timeline.offsetTop) + "px")
          })
          .on("mouseout", (d, i) => {
            tooltip.transition()
              .duration(200)
              .style("opacity", 0.0)
          })
          .on("click", (d, i) => {
            this.props.itemClicked(d, datum, false);
          })
          .attr("class", (d, i) => {
            return datum.class ? "timelineSeries_"+datum.class : "timelineSeries_"+index;
          })
          .attr("id", (d, i) => {
            // use deprecated id field
            if (datum.id && !d.id) { return 'timelineItem_'+datum.id; }
            return d.id ? d.id : "timelineItem_"+index+"_"+i;
          });
      });
  }

  render() {
    return (
      <div id={this.props.id}>
        <div className="tooltip" ref="tooltip" />
      </div>
    );
  }
}

export default TimelineDrawing;
