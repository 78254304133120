// Library Imports
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
//Function and Component Imports
import SignOutButton from 'components/SignOutButton';
// Style Imports
import 'styles/buttons.css';

export default class Toolbar extends Component {
  render() {
    return (
      <Fragment>
      <div id="toolbar">
        <Link to="/" className="nav-link">Home</Link>
        <Link to="/timeline" className="nav-link">Tracker</Link>
        <Link to="/what" className="nav-link">What</Link>
        <Link to="/who" className="nav-link">Who</Link>
        <Link to="/how" className="nav-link">How</Link>
        {this.props.authUser?
          <SignOutButton/>
          :
          <Link to="/login" className="nav-link">Sign In</Link>
        }
        <Link to="/comment" className="nav-link-taller">Submit Comment</Link>
      </div>
      </Fragment>
    );
  }
}