import React from 'react';
import AuthUserContext from './context';
import { withFirebase } from '../firebase';

// Higher order Component with wraps App.js in order to handle the
// logic of tracking the logged in user
// This Component also supplies the authUser to AuthUserContext
const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        authUser: null
      };
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          if (!authUser) {
            this.setState({ authUser: null });
          } else {
            var ref = this.props.firebase.user(authUser.uid);
            ref.on('value', (snapshot) => {
              if (snapshot.val()) {
                this.setState({ authUser: snapshot.val() })
              } else {
                this.setState({ authUser: null });
              }
            });
          }
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }
  return withFirebase(WithAuthentication);
}

export default withAuthentication;
