import axios from 'axios';

export function SubmitComment(subject, text, callback) {
  const url = "https://us-central1-trumptracker-f6d14.cloudfunctions.net/sendMail";
  axios.post(url, {
    subject: subject,
    text: text,
    dest: "trackerimm.contact@gmail.com"
  })
  .then(function (response) {
    if (callback) {
      callback(response);
    }
  });
}

// Send an Email with subject and text
export function SendEmail(email, subject, text, callback=null) {
  const url = "https://us-central1-trumptracker-f6d14.cloudfunctions.net/sendMail";
  axios.post(url, {
    subject: subject,
    text: text,
    dest: email
  })
  .then(function (response) {
    if (callback) {
      callback(response);
    }
  });
}
