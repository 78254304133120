// Library Imports
import React, { Component } from 'react';
// Component and Function Imports
import { withFirebase } from 'services/firebase';

const INITIAL_STATE = {
  email: '',
  error: null,
}

class PasswordForget extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    event.preventDefault();
    const { email } = this.state;
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { email, error} = this.state;
    const isInvalid = email === '';
    return (
      <form onSubmit={this.onSubmit}>
        <input
          name="email"
          value={this.state.email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
          />
        <button disabled={isInvalid} type="submit" className="nav-button">
          Send Password Resent Link
        </button>
        {
          error
          ? <div className="explanation"> {error.message} </div>
          : null
        }
      </form>
    );
  }
}

export default withFirebase(PasswordForget);
