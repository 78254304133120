/*
built using this tutorial:
https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial/
*/
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
// Initialize Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyCzZQ7FRrrsINIkAMaMefsI9ipaPrJ35-s',
  authDomain: 'trumptracker-f6d14.firebaseapp.com',
  databaseURL: 'https://trumptracker-f6d14.firebaseio.com',
  projectId: 'trumptracker-f6d14',
  storageBucket: 'trumptracker-f6d14.appspot.com',
  messagingSenderId: '5205741698',
  appId: '1:5205741698:web:a2280f65bd236fd8'
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();
    this.db = app.database();
    // this.googleProvider = new app.auth.GoogleAuthProvider();
  }

  // ** Auth API **
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = password => this.auth.createUser.updatePassword(password);

  // ** User API ***
  user = uid => this.db.ref(`users/${uid}`);
  users = () => this.db.ref('users');
  grantAccess = uid => {

  }
}

export default Firebase;
