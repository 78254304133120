export function StringifyJSON(o) {
  var cache = [];
  const x = JSON.stringify(o, function(key, value) {
    if (typeof value === 'object' && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Duplicate reference found
        try {
          // If this value does not reference a parent it can be deduped
          return JSON.parse(JSON.stringify(value));
        } catch (error) {
          // discard key if value cannot be deduped
          return;
        }
      }
      // Store value in our collection
      cache.push(value);
    }
    return value;
  });
  cache = null;
  return x;
}

export function CopyObject(o) {
  return JSON.parse(StringifyJSON(o));
}

export function HexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
  } : null;
}

// TODO: IMPROVE THIS BY MAKING IT MORE ROBUST AGAINST
// POTENTIAL CROSS SITE SCRIPTING ATTACKS
export function CleanText(val) {
  const tagRegex = /<[^>]+>/g;
  return val.replace(tagRegex, "").trim();
}
