// Library Imports
import React, { Component, Fragment } from 'react';
// Component and Function Imports
import { FetchAbout } from 'services/fetchData';
import Header from 'components/Header';
// Style Imports
import './LucasBio.css';
class LucasBio extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    FetchAbout((categories, about, lucasBio, contact) => {
      // acknowledgements is an Obj with [[people], color]
      this.setState({
        lucasDescription: lucasBio,
      });
    });
  }

  render() {
    return (
      <Fragment>
        <Header page="admin"/>
        <div id="lucas-bio-container">
          <div id="title">Lucas Guttentag</div>
          <div className="text">
            {this.state.lucasDescription}
          </div>
        </div>
      </Fragment>
      );
  }
}

export default LucasBio;