// Library Imports Library
import React from 'react';
import ReactDOM from 'react-dom';

import Firebase, { FirebaseContext } from './services/firebase';

// Function and Component Imports
import App from './App';
import * as serviceWorker from './serviceWorker';
// Styles Imports
import './styles/index.css';

// const app = firebase.app();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

// render App with surrounding Firebase Context to ensure that every component
// can access the Firebase instance and that it is only intiated once
ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();
