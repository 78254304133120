// Library Imports
import React, { Component, Fragment } from 'react';
// Media Imports
import lucasPhoto from 'images/lucas_photo_2.jpg';
// Function and Component Imports
import Header from 'components/Header';
import Loading from 'components/Loading';
import { withAuthorization } from 'services/session';
import { FetchAbout } from 'services/fetchData';
import { FetchTeam } from 'services/fetchData';
// Style Imports
import './Who.css';
import 'styles/AboutPages.css'

class Who extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories_intro: 'Policy changes are organized by categories. Each new policy appears in only one category. Use the search function to find policies that related to multiple categories.',
      categories: {},
    };
  }

  componentDidMount() {
    FetchAbout((categories, about, lucasBio, contact) => {
      // acknowledgements is an Obj with [[people], color]
      this.setState({
        lucasDescription: lucasBio.split('\n'),
      });
    });
    FetchTeam((acknowledgements) => {
      // acknowledgements is an Obj with [[people], color]
      this.setState({
        acknowledgements: acknowledgements,
      });
    });
  }

  /*
    \params:
      content - str - string of content, newlines will be replaced with <br>
    \returns:
      res - [elems] - list of <p> for each string, ceperated by <br>
  */
  buildParagraphs = (content) => {
    let res = [];
    for (let i = 0; i < content.length; i++) {
      res.push(
        <p className='title-description' key={i}>{content[i]}</p>
      );
      if (i < content.length - 1) {
        res.push(<br key={'br-' + i}/>);
      }
    }
    return res;
  }

  sortByLastName = (name1, name2) => {
    // console.log(name1, name2);
    let names1 = name1.split(' '), names2 = name2.split(' ');
    if (names1.length < 2) return -1;
    if (names2.length < 2) return 1;
    return (names1[1] < names2[1])? -1: 1
  }

  /*
    returns a list of category divs containing the category's
      - title
      - description
  */
  buildTeam = () => {
    let team = [];
    for (let i = 0; i < this.state.acknowledgements.length; i++) {
      let acknoCategory = this.state.acknowledgements[i];
      acknoCategory.people.sort(this.sortByLastName);
      var people = [];
      for (let j = 0; j < acknoCategory.people.length; j++) {
        people.push((
          <div className='team-member' key={j}>
            {acknoCategory.people[j]}
          </div>
        ));
      }
      team.push((
        <div className='acknowledgement-category' key={acknoCategory.type}>
          <h3 className='category-title title'>{acknoCategory.type}</h3>
          <div className='acknowledgement-barrier'></div>
          <div className='team-list'>
            {people}
          </div>
        </div>
      ));
    }
    return team;
  }

  render() {
    // div containing title and who introduction 
    let about_content_container = this.state.lucasDescription? (
        <div className='about-title-description-container'>
          <div className='about-title-container'>
            <h2 className='about-title'>Creating the Tracking Project</h2>
            </div>
          <h2 className='about-subtitle'>Professor Lucas Guttentag</h2>
          <img src={lucasPhoto} alt='Lucas Guttentag'></img>
          {this.buildParagraphs(this.state.lucasDescription)}
        </div>
      )
      :
      <Loading />;


    // list of team
    let team_list = this.state.acknowledgements? (
        <div id='team-container'>
          <div id='team-introduction' className='about-content-header'>
            <h2 id='team-title' className='content-title'>Made possible with help from...</h2>
          </div>
          <div id='team-list'>
            {this.buildTeam()}
          </div>
        </div>
        )
      :
      <Loading />;

    return(
      <Fragment>
        <Header page='who'/>
        <div className='about-container'>
          {about_content_container}
          {team_list}
        </div>
      </Fragment>
    )
  }
}

const condition = authUser => {
  return (authUser && authUser.timelineAccess);
}
// protect Timeline with condition
export default withAuthorization(condition)(Who);
