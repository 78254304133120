/*
  functions used to fetch data for Team Container
*/
import { FetchSheets } from './helpers';
import { TEAM_SHEET_INDEX, DEFAULT_COLOR } from './constants';
// fetch data for team
//
// applies callback to an array of objects, each representing
// a thanks category {people: [string], color: string, type: string}
// people are orgranized alphabetically by first name and the categories
// are ordered by size
function FetchTeam(callback) {
  // fetch last sheet
  // sheetsRes - response object
  FetchSheets([TEAM_SHEET_INDEX], (sheetsRes) => {
    var teamData = sheetsRes.data.valueRanges[0].values;
    var acknowledgements = {};
    for (var i = 0; i < teamData.length; i++) {
      // each team data contains [name, thanksType, (color)]
      const thanksType = teamData[i][1];
      if (acknowledgements[thanksType]) {
        acknowledgements[thanksType].people.push(teamData[i][0]);
      }
      else {
        acknowledgements[thanksType] = {
          people: [teamData[i][0]],
          color: DEFAULT_COLOR,
          type: thanksType
        };
      }
      if (teamData[i][2]) { acknowledgements[thanksType].color = teamData[i][2]; }
    }
    for (var thanksCat of Object.values(acknowledgements)) {
      thanksCat.people.sort((name1, name2) => {
        // sort alphabetically by last name
        var name1s = name1.split(" "), name2s = name2.split(" ");
        var last1 = name1s[name1s.length - 1], last2 = name2s[name2s.length - 1]
        return last1.localeCompare(last2);  // compare strings like strcmp from c
      });
    }
    // sort categories by length
    var orderedAck = [];
    Object.keys(acknowledgements).sort((thanksType1, thanksType2) => {
      return acknowledgements[thanksType2].people.length - acknowledgements[thanksType1].people.length;
    }).forEach((key) => {
      orderedAck.push(acknowledgements[key]);
    });
    callback(
      orderedAck // [{people: [string], color: string, type: string}]
    );
  });
}

export default FetchTeam;
