// Library Imports
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
// Function and Component Imports
import Header from 'components/Header';
import Loading from 'components/Loading';
import { withAuthorization } from 'services/session';
import { FetchAbout } from 'services/fetchData';
// Style Imports
import './What.css';
import 'styles/AboutPages.css'

class What extends Component {
  constructor(props) {
    super(props);
    this.state = {
      what_introduction: ['The Immigration Policy Tracking Project is the only comprehensive catalogue of every immigration policy adopted by the Trump administration since January 2017.  Entries link to source documents and any predecessor policies.', 'The Tracking Project is designed to serve as a tool for monitoring and reversing the immigration policies of the Trump administration. The link to prior policies is for analysis and comparison; it is not an endorsement of any specific policy.', 'The Tracker site is accessible to authorized users. Please register or sign in for access.'],
      categories_intro: 'Policy changes are organized by categories. Each new policy appears in only one category. Use the search function to find policies that relate to multiple categories.',
    };
  }

  componentDidMount() {
    document.body.classList.toggle('dark', false);
    FetchAbout((categories, about, lucasBio, contact) => {
      // acknowledgements is an Obj with [[people], color]
      this.setState({
        categories: categories,
        aboutDescription: about,
        lucasDescription: lucasBio,
        contact: contact
      });
    });
  }

  /*
    \params:
      content - [str]- list of strings that are to be formatted into paragraps
    \returns:
      res - [elems] - list of <p> for each string, ceperated by <br>
  */
  buildParagraphs = (content) => {
    let res = [];
    for (let i = 0; i < content.length; i++) {
      res.push(
        <p className='title-description' key={i}>
          {content[i]}
        </p>
      );
      if (i < content.length - 1) {
        res.push(<br key={'br-' + i}/>);
      }
    }
    return res;
  }

  /*
    returns a list of category divs containing the category's
      - title
      - description
  */
  buildCategories = () => {
    let categories = [];
    for (var i = 0; i < this.state.categories.length; i++) {
      const category = this.state.categories[i];
      categories.push((
        <Link to={{pathname: '/timeline', state: { category: category}}} key={i}>
          <div className='category-container'>
            <h2 className='category-title content'>{category.label.replace(/\//g, ", ")}</h2>
            <hr />
            <p className='category-description content'>{category.description}</p>
          </div>
        </Link>
      ));
    }
    return categories;
  }

  render() {
    // div containing title and what introduction 
    let about_content_container = (
      <div className='about-title-description-container'>
        <div className='about-title-container'><h2 className='about-title'>What is the Tracking Project?</h2></div>
        {this.buildParagraphs(this.state.what_introduction)}
      </div>
    );

    // list of categories to be displayed
    let categories_container = this.state.categories? (
        <div id='categories-container'>
          <div id='categories-introduction' className='about-content-header'>
            <h2 id='categories-title' className='content-title'>Categories</h2>
            <p id='categories-introduction' className='content-introduction'>{this.state.categories_intro}</p>
          </div>
          <div id='categories-list'>
            {this.buildCategories()}
          </div>
        </div>
        )
      :
      <Loading />;

    return(
      <Fragment>
        <Header page='what'/>
        <div className='about-container'>
          {about_content_container}
          {categories_container}
          <div id='about-footer'></div>
        </div>
      </Fragment>
    )
  }
}

const condition = authUser => {
  return (authUser && authUser.timelineAccess);
}
// protect Timeline with condition
export default withAuthorization(condition)(What);